import { getCourse, getCourses } from "@/api/course.api";
import { getCourseHours, UpdateCourseHour } from "@/api/hour.api";
import { RootStoreState } from "@/store/root-store.state.interface";
import { ActionTree, MutationTree } from "vuex";
import { getField, updateField } from "vuex-map-fields";
import { HoursStoreState } from "@/store/modules/hours/hours.store.interface";
import { HoursActions } from "@/store/modules/hours/hours.actions.enum";
import { HoursMutations } from "@/store/modules/hours/hours.mutations.enum";

const initialState: () => HoursStoreState = () => ({
  hours: [],
  courseHours: [],
});

const state = initialState();

const getters = {
  getField,
  getHours(state: any) {
    return state.hours;
  },
  getCourseHours(state: any) {
    return state.courseHours;
  },
};

const actions = <ActionTree<HoursStoreState, RootStoreState>>{
  [HoursActions.Reset]({ commit }) {
    commit(HoursMutations.RESET);
  },
  async [HoursActions.FetchHours]({ commit, dispatch }) {
    try {
      dispatch("hoc/setLoading", null, { root: true });
      const coursesIds: any[] = [];
      const courses = await getCourses();
      courses.data.forEach((course: any) => {
        coursesIds.push(course.courseId);
      });
      const plans: any[] = [];
      // Get plans for all courses
      for (let i = 0; i < coursesIds.length; i++) {
        const course = await getCourse(coursesIds[i]);
        course.data.plan.schedules.forEach((day: any) => {
          const dayHours = {
            courseId: coursesIds[i],
            courseName: course.data.courseName,
            scheduleStart: course.data.startDate,
            scheduleEnd: course.data.startDate,
            start: day.start,
            end: day.end,
            title: day.title,
            hours: "",
          };
          plans.push(dayHours);
        });
      }
      dispatch("hoc/removeLoading", null, { root: true });
      commit(HoursMutations.SET_HOURS, plans);
    } catch (error) {
      dispatch("hoc/removeLoading", null, { root: true });
      console.error(error);
    }
  },
  async [HoursActions.FetchCourseHours]({ commit, dispatch }, courseId) {
    try {
      dispatch("hoc/setLoading", null, { root: true });
      /* TODO: Find out if getCourseHours parameter courseId is needed */
      const response = await getCourseHours(courseId);
      dispatch("hoc/removeLoading", null, { root: true });
      commit(HoursMutations.SET_COURSE_HOURS, { data: response.data });
    } catch (error) {
      dispatch("hoc/removeLoading", null, { root: true });
      console.error(error);
    }
  },
  /* Need to fix error with Hours not being on "RootStoreState" */
  async [HoursActions.UpdateHour]({ commit, state }, dateId) {
    const day = state.courseHours.filter((item: any) => {
      if (item.id === dateId) {
        return item;
      }
    });
    const response = await UpdateCourseHour(day);
    const dayIndex = state.courseHours.findIndex((courseHour: any) => courseHour.id === dateId);
    const updatedHours = state.courseHours;
    updatedHours[dayIndex] = response.data[0];
    commit(HoursMutations.SET_COURSE_HOURS, { days: updatedHours });
  },
};

const mutations = <MutationTree<HoursStoreState>>{
  updateField,
  //  Will always add a reset mutation so we can use the global reset.
  [HoursMutations.RESET](state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key as keyof HoursStoreState] = newState[key as keyof HoursStoreState];
    });
  },
  [HoursMutations.SET_HOURS](state, data) {
    state.hours = data;
  },
  [HoursMutations.SET_COURSE_HOURS](state, { days: days }) {
    state.courseHours = days;
  },
};

export const HoursModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
