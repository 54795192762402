import { Route } from "vue-router";
export const CoursesRouteList = [
  // //  Courses
  /* Unsure about naming for expiredCourseList => TheCourseListPage */
  {
    path: "/course/allCourses",
    name: "expiredCourseList",
    component: () => import(/* webpackChunkName: "CourseListPage" */ "@/pages/courses/CourseListPage.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { text: "Dashbord", disabled: false, href: "/" },
        {
          text: "Kurs og studier",
          disabled: true,
          href: "/course",
        },
      ],
    },
  },
  {
    path: "/course/:tab?",
    name: "courseList",
    component: () => import(/* webpackChunkName: "courseListPage" */ "@/pages/courses/ActiveCourseListPage.vue"),
    props: (route: Route) => ({
      routeTab: route.params.tab,
    }),
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { text: "Dashbord", disabled: false, href: "/" },
        {
          text: "Kurs og studier",
          disabled: true,
          href: "/course",
        },
      ],
    },
  },
  // We shouldn't be dependent on sending userStatus and appliedViaForm as params in the url as this make routing to single course page complicated
  // TODO figure out a way to get userStatus and appliedViaForm in the single course page instead of sent via the url.
  {
    path: "/course/:id/:userStatus/:appliedViaForm?/:tab?",
    name: "singleCourse",
    props: (route: Route) => ({
      routeTab: route.params.tab,
    }),
    component: () => import(/* webpackChunkName: "SingleCoursePage" */ "@/pages/courses/SingleCoursePage.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { text: "Dashbord", disabled: false, href: "/" },
        {
          text: "Kurs og studier",
          disabled: false,
          href: "/course",
        },
        {
          text: "Kurs",
          disabled: true,
          to: { name: "singleCourse" },
        },
      ],
    },
  },
  {
    path: "/course-refund/:id",
    name: "singleCourseRefundForm",
    component: () =>
      import(/* webpackChunkName: "CourseNewRefundClaim" */ "@/pages/courses/CourseNewRefundClaimPage.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ text: "Dashbord", disabled: false, href: "/" }],
    },
  },
  {
    path: "/course-refund/:id/:refundClaimId",
    name: "singleCourseRefundEditForm",
    component: () =>
      import(/* webpackChunkName: "CourseNewRefundClaim" */ "@/pages/courses/CourseNewRefundClaimPage.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ text: "Dashbord", disabled: false, href: "/" }],
    },
  },
  {
    path: "/courseInvites",
    name: "courseInvitationList",
    component: () =>
      import(/* webpackChunkName: "CourseInvitationListPage" */ "@/pages/courses/CourseInvitationListPage.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { text: "Dashbord", disabled: false, href: "/" },
        {
          text: "Kursinvitasjoner",
          disabled: true,
          href: "/courseInvites",
        },
      ],
    },
  },
  {
    path: "/courseAssignment/:tab?",
    name: "courseAssignment",
    component: () =>
      import(/* webpackChunkName: "ActiveAssignmentListPage" */ "@/pages/courses/ActiveAssignmentListPage.vue"),
    props: (route: Route) => ({
      routeTab: route.params.tab,
    }),
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { text: "Dashbord", disabled: false, href: "/" },
        {
          text: "Oppdrag",
          disabled: true,
          href: "/courseAssignment",
        },
      ],
    },
  },
  {
    path: "/courseAssignment/:id/:userStatus/:tab?",
    name: "singleAssignmentCourse",
    props: (route: Route) => ({
      routeTab: route.params.tab,
    }),
    component: () =>
      import(/* webpackChunkName: "SingleAssignmentCoursePage" */ "@/pages/courses/SingleAssignmentCoursePage.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { text: "Dashbord", disabled: false, href: "/" },
        {
          text: "Oppdrag",
          disabled: false,
          href: "/courseAssignment",
        },
        {
          text: "Kurs",
          disabled: true,
          exact: true,
          to: { name: "singleAssignmentCourse" },
        },
      ],
    },
  },
  {
    path: "/courseAssignment/:courseId/attendance/:scheduleStart",
    name: "courseAttendanceParticipant",
    props: true,
    component: () => import("@/pages/courses/CourseAttendanceParticipantPage.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { text: "Dashbord", disabled: false, href: "/" },
        {
          text: "Oppdrag",
          disabled: false,
          href: "/courseAssignment",
        },
        {
          text: "Fremmøte",
          disabled: true,
          exact: true,
          to: { name: "courseAttendance" },
        },
      ],
    },
  },
];
