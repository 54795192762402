import { differenceInMinutes, format, formatISO, formatRelative as fnsFormatRelative, isValid, parse } from "date-fns";
import { nb } from "date-fns/locale";

export function FormatDate(date: any) {
  if (!date) {
    return null;
  }
  const nd = date.substring(0, date.lastIndexOf("T"));
  const [year, month, day] = nd.split("-");
  return `${day}/${month}/${year}`;
}

export function formatTime(date: Date | string) {
  return new Date(date).toLocaleString("no-NO", {
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function formatDate(date: Date | string) {
  return new Date(date).toLocaleString("no-NO", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
}

export function formatDateTime(date: Date | string) {
  return new Date(date).toLocaleString("no-NO", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function formatCourseDates(list: any) {
  if (!list) {
    return [];
  }
  if (list.length === 0) {
    return [];
  }
  // This does noting! Need fix, but need to be verified
  list.forEach((item: any) => {
    item.course.cancellationDeadline = !item.course.cancellationDeadline
      ? null
      : formatLocalizedDate(item.course.cancellationDeadline);
    item.course.enrollmentDeadline = !item.course.enrollmentDeadline
      ? null
      : formatLocalizedDate(item.course.enrollmentDeadline);
    item.course.endDate = formatLocalizedDate(item.course.endDate);
    item.course.startDate = formatLocalizedDate(item.course.startDate);
  });
  return list;
}

export function FormatHour(date: any) {
  if (!date) {
    return null;
  }
  const nd = new Date(date);
  const addZero = (value: any) => {
    if (value < 10) {
      value = "0" + value;
    }
    return value;
  };
  const hours = addZero(nd.getHours());
  const minutes = addZero(nd.getMinutes());
  return `${hours}:${minutes}`;
}

export function FormatISODate(event: any) {
  if (!event) {
    return null;
  }
  // const [day, month, year] = event.target.value.split("/");
  const [day, month, year] = event.split("/");
  const date = year + "-" + month + "-" + day + "T00:00:00";
  return date;
}

export function isValidDate(day: string, month: string, year: string) {
  return isValid(parse(`${day}/${month}/${year}`, "dd/MM/yyyy", new Date()));
}

export function AvailableHours(course: any, dayId: any) {
  if (!course.plan.schedules || !dayId) {
    return [];
  }
  const startHour = new Date(course.plan.schedules[dayId].start).getHours();
  const endHour = new Date(course.plan.schedules[dayId].end).getHours();
  const hoursNumber = endHour - startHour;
  const hours = [];
  for (let i = 0; i <= hoursNumber; i = i + 0.5) {
    hours.push(i);
  }
  return hours;
}

export function formatDateTimeRelative(date: Date | string) {
  if (typeof date === "string") {
    date = new Date(date);
  }
  const d = date instanceof Date ? date : new Date(date);
  return fnsFormatRelative(d, new Date(), { locale: nb });
}

export function formatLocalizedTime(inputDate: Date | string) {
  const date = inputDate instanceof Date ? inputDate : new Date(inputDate);
  return format(date, "p", { locale: nb });
}

export function formatLocalizedDate(date: Date | string, formatStr = "PP") {
  const newDate = date instanceof Date ? date : new Date(date);
  return format(new Date(newDate), formatStr, { locale: nb });
}

export function formatLocalizedDateTime(date: Date | string) {
  const newDate = date instanceof Date ? date : new Date(date);
  return format(new Date(newDate), "Pp", { locale: nb });
}

export const getIsoDate = (date: Date | string): string => {
  if (typeof date === "string") {
    date = new Date(date);
  }
  return formatISO(date, { representation: "date" });
};

export const getRoundedHours = (hours?: number, addSuffix = true) => {
  const suffix = hours === 1 ? " time" : " timer";
  return `${(+(hours ?? 0).toFixed(1)).toLocaleString()}${addSuffix ? suffix : ""}`;
};

export const createDateTime = (date: string, time: string): Date => {
  const timeSplit = time.split(":");

  if (timeSplit.length < 2) {
    return new Date();
  }

  const newDate = new Date(date);
  newDate.setHours(+timeSplit[0]);
  newDate.setMinutes(+timeSplit[1]);

  return newDate;
};

export const getTimeDifferenceInMinutes = (fromTime?: Date | string, toTime?: Date | string) => {
  if (fromTime && toTime) {
    const fromDateTime = new Date(fromTime);
    const toDateTime = new Date(toTime);

    return differenceInMinutes(toDateTime, fromDateTime);
  }
};

export function convertMinutesToHours(totalMinutes: number) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return { hours, minutes };
}
