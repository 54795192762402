
import AuthGuard from "@/components/shell/AuthGuard.vue";
import TheDrawer from "@/components/shell/app-router/TheDrawer.vue";
import TheModalContainer from "@/components/shell/app-router/TheModalContainer.vue";
import TheNavIcons from "@/components/shell/app-router/TheNavIcons.vue";
import TheNotificationDisplayer from "@/components/shell/app-router/TheNotificationDisplayer.vue";
import TheSkeletonLoader from "@/components/shell/app-router/TheSkeletonLoader.vue";
import TheSnackbar from "@/components/shell/app-router/TheSnackbar.vue";
import { useRouter, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import "@/styles/global.scss";
import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import { isMobile } from "./shared/helpers/displayHelpers";
import { globalLoadingWrapper } from "./shared/helpers/loadingHelpers";
import { runStoreAction } from "./shared/helpers/store.helpers";
import { StoreModules } from "./store/store-modules.enum";
import { StoreActions } from "./store/store.actions";

export default defineComponent({
  name: "App",
  components: {
    TheNavIcons,
    TheDrawer,
    TheModalContainer,
    TheSnackbar,
    TheNotificationDisplayer,
    TheSkeletonLoader,
    AuthGuard,
  },
  setup() {
    const router = useRouter();
    const store = useStore<StoreState>();
    const isAuthenticated = ref(true);
    const signupCompleted = computed(() => store.state.customer.signupCompleted);

    watch(signupCompleted, () => {
      if (signupCompleted.value === false) {
        return router.push({ path: "/completeSignup" });
      }
    });

    router.beforeResolve((to, from, next) => {
      if (
        to.name === "SignupPage" ||
        to.name === "LoginCallback" ||
        from.name === "LoginCallback" ||
        to.name === "TheProfileCallback"
      ) {
        return next();
      }

      if (signupCompleted.value === false) {
        return next({ path: "/completeSignup" });
      }

      next();
    });

    const handleAuthenticated = () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await Promise.all([
          runStoreAction(store, StoreModules.Customer, StoreActions.CustomerActions.FetchCustomer),
          runStoreAction(store, StoreModules.Hoc, StoreActions.HocActions.FetchCustomerResource),
        ]);
      });
    };

    return {
      signupCompleted,
      handleAuthenticated,
      isAuthenticated,
      isMobile,
      showLoadingSpinner: computed(() => store.state.hoc.showLoadingSpinner),
      ongoingBlockingRequests: computed(() => store.state.hoc.ongoingBlockingRequests),
      ongoingNonBlockingRequests: computed(() => store.state.hoc.ongoingNonBlockingRequests),
      ongoingTableSkeletonRequests: computed(() => store.state.hoc.ongoingTableSkeletonRequests),
    };
  },
});
