import { RootStoreState } from "@/store/root-store.state.interface";
import { ActionTree, MutationTree } from "vuex";
import { GradesStoreState } from "@/store/modules/grades/grades.store.interface";
import { GradesActions } from "@/store/modules/grades/grades.actions.enum";
import { GradesMutations } from "@/store/modules/grades/grades.mutations.enum";

const initialState: () => GradesStoreState = () => ({
  grades: [],
});

const state = initialState();

const getters = {
  getGrades(state: any) {
    return state.grades;
  },
};

const actions = <ActionTree<GradesStoreState, RootStoreState>>{
  [GradesActions.Reset]({ commit }) {
    commit(GradesMutations.RESET);
  },
};

const mutations = <MutationTree<GradesStoreState>>{
  //  Will always add a reset mutation so we can use the global reset.
  [GradesMutations.RESET](state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key as keyof GradesStoreState] = newState[key as keyof GradesStoreState];
    });
  },
};

export const GradesModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
