import { securedHttpClient } from "@/api/httpClient";

export const static_register = (customer: any) =>
  securedHttpClient.post(`/login/signup`, {
    email: customer.email,
    password: customer.password,
    customerID: customer.id,
  });

export const reset_password = (email: any) => securedHttpClient.post(`/login/ResetPassword?email=${email}`);

export const new_password = (payload: any) =>
  securedHttpClient.put(`/login/password`, {
    verificationToken: payload.token,
    password: payload.password,
  });

export const getCustomer = () => securedHttpClient.get(`/minside/customer/person/`);

export const getCustomerKompetanse = (custId: any) => {
  return securedHttpClient.get(`/minside/customer/person/`, {
    headers: {
      userId: custId,
    },
  });
};

export const updatecustomer = (customer: any, custId: any) =>
  securedHttpClient.put(`/customer/person/${custId}`, customer);

export const createcustomer = (customer: any) =>
  securedHttpClient.post(`login/customer/person`, {
    organizationId: 1,
    postAddress1: customer.address,
    postAddress2: "",
    postAddress3: "",
    postCity: customer.zipPlace,
    postZip: customer.zip,
    postCountry: customer.countryCode,
    invoiceAddress1: customer.address,
    invoiceAddress2: "",
    invoiceAddress3: "",
    invoiceCity: customer.zipPlace,
    invoiceZip: customer.zip,
    invoiceCountry: customer.countryCode,
    phoneNumber: "",
    mobileNumber: customer.phone,
    email: customer.email,
    isActive: true,
    customer: {
      title: "",
      firstName: customer.first_name,
      middleName: "",
      lastName: customer.last_name,
      birthDate: customer.birthDate,
      ssno: customer.ssno,
      gender: customer.gender,
      allowDm: true,
      adminAccess: false,
    },
  });
