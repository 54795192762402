
import { authService } from "@/authService";
import { getFullName, getShortName } from "@/shared/helpers/customerHelpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "TheProfileInfo",
  setup() {
    const store = useStore<StoreState>();
    const customer = computed(() => store.state.customer.customer);
    return {
      signOut: () => authService.logout(),
      customerShortName: computed(() => getShortName(customer.value)),
      customerFullName: computed(() => getFullName(customer.value)),
    };
  },
});
