import { new_password, reset_password } from "@/api/login.api";
import { SigninActions } from "@/store/modules/signin/signin.actions.enum";
import { SigninMutations } from "@/store/modules/signin/signin.mutations.enum";
import { SigninStoreState } from "@/store/modules/signin/signin.store.interface";
import { RootStoreState } from "@/store/root-store.state.interface";
import { ActionTree, MutationTree } from "vuex";
import { getField, updateField } from "vuex-map-fields";

const initialState: () => SigninStoreState = () => ({
  signIn: {},
  reset: {
    email: null,
    confirmation: false,
  },
  new: {
    password: null,
    verificationToken: null,
    confirmation: false,
  },
});

const state = initialState();

const getters = {
  getField,
};

const actions = <ActionTree<SigninStoreState, RootStoreState>>{
  [SigninActions.Reset]({ commit }) {
    commit(SigninMutations.RESET);
  },
  async [SigninActions.Reset_Password]({ commit }) {
    try {
      commit(SigninMutations.SET_RESET_CONFIRMATION, true);
      await reset_password(state.reset.email);
      commit(SigninMutations.CLEAR_RESET_PASSWORD);
    } catch (error) {
      console.error(error);
    }
  },
  async [SigninActions.New_Password]({ commit }) {
    try {
      const request = {
        password: btoa(state.new.password),
        token: state.new.verificationToken,
      };
      const response = await new_password(request);
      if (response.status >= 200 && response.status < 300) {
        commit(SigninMutations.SET_NEW_PASSWORD_CONFIRMATION, true);
        commit(SigninMutations.CLEAR_NEW_PASSWORD);
      }
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = <MutationTree<SigninStoreState>>{
  updateField,
  [SigninMutations.RESET](state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key as keyof SigninStoreState] = newState[key as keyof SigninStoreState];
    });
  },
  [SigninMutations.SET_RESET_CONFIRMATION](state, value) {
    state.reset.confirmation = value;
  },
  [SigninMutations.CLEAR_RESET_PASSWORD](state) {
    state.reset.email = null;
  },
  [SigninMutations.SET_VERIFICATION_TOKEN](state, value) {
    state.new.verificationToken = value;
  },
  [SigninMutations.SET_NEW_PASSWORD_CONFIRMATION](state, value) {
    state.new.confirmation = value;
  },
  [SigninMutations.CLEAR_NEW_PASSWORD](state) {
    state.new.password = null;
    state.new.verificationToken = null;
  },
};

export const SigninModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
