var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.isMobile ? _c('v-navigation-drawer', {
    attrs: {
      "mini-variant": _vm.mini,
      "mini-variant-width": 80,
      "permanent": "",
      "app": ""
    },
    on: {
      "update:miniVariant": function ($event) {
        _vm.mini = $event;
      },
      "update:mini-variant": function ($event) {
        _vm.mini = $event;
      }
    },
    scopedSlots: _vm._u([!_vm.mini ? {
      key: "append",
      fn: function () {
        return [_c('div', {
          staticClass: "pa-2"
        }, [_c('v-btn', {
          staticClass: "text-overline",
          attrs: {
            "color": "primary",
            "block": ""
          },
          on: {
            "click": _vm.signOut
          }
        }, [_vm._v("Logg ut")])], 1)];
      },
      proxy: true
    } : null], null, true),
    model: {
      value: _vm.drawer,
      callback: function ($$v) {
        _vm.drawer = $$v;
      },
      expression: "drawer"
    }
  }, [_c('v-toolbar', {
    staticClass: "v-bar--underline",
    attrs: {
      "flat": ""
    }
  }, [!_vm.mini ? _c('v-list-item', {
    staticClass: "flex-1-1-auto d-flex align-center justify-center"
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "https://aofnorge.wpengine.com/wp-content/uploads/2020/10/aof-logo.svg",
      "max-height": "40",
      "max-width": "80",
      "contain": ""
    }
  })], 1)], 1) : _vm._e(), _c('v-btn', {
    class: _vm.mini ? 'mx-0' : '',
    attrs: {
      "icon": "",
      "data-cy": "collapseMenu"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.mini = !_vm.mini;
      }
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.mini ? "mdi-chevron-right" : "mdi-chevron-left"))])], 1)], 1), _c('v-list-item', {
    staticClass: "px-2",
    attrs: {
      "color": "primary"
    }
  }, [_c('v-list-item-avatar', {
    attrs: {
      "color": "primary",
      "data-cy": "avatar",
      "rounded": ""
    },
    on: {
      "click": _vm.navigateToProfile
    }
  }, [_c('span', {
    staticClass: "white--text headline enter-profile"
  }, [_vm._v(_vm._s(_vm.customerShortName))])]), _c('v-list-item-title', {
    staticClass: "enter-profile text-overline",
    attrs: {
      "data-cy": "fullName"
    },
    on: {
      "click": _vm.navigateToProfile
    }
  }, [_vm._v(" " + _vm._s(_vm.customerFullName) + " ")])], 1), _c('v-divider', {
    staticClass: "mx-2"
  }), _c('v-list', {
    attrs: {
      "nav": ""
    }
  }, _vm._l(_vm.menuItemsDisplayed, function (item) {
    return _c('v-list-item', {
      key: item.title,
      attrs: {
        "color": "primary",
        "data-cy": "navigation-item",
        "link": "",
        "to": item.link
      }
    }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v(_vm._s(item.icon) + " ")]), item.badgeKey && _vm.badges[item.badgeKey] ? _c('v-badge', {
      attrs: {
        "content": _vm.badges[item.badgeKey],
        "color": item.badgeColor
      }
    }) : _vm._e()], 1), _c('v-list-item-title', {
      staticClass: "text-overline"
    }, [_vm._v(_vm._s(item.title))])], 1);
  }), 1)], 1) : _c('div', [_c('v-app-bar', {
    attrs: {
      "color": "primary",
      "fixed": "",
      "dark": "",
      "elevate-on-scroll": ""
    }
  }, [_c('v-app-bar-nav-icon', {
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.drawer = !_vm.drawer;
      }
    }
  }), _c('v-toolbar-title', [_vm._v(_vm._s(_vm.activeTitle))]), _c('v-spacer'), _c('TheHelpButton'), _c('TheNotifications')], 1), _c('v-navigation-drawer', {
    attrs: {
      "fixed": "",
      "app": "",
      "bottom": "",
      "temporary": ""
    },
    model: {
      value: _vm.drawer,
      callback: function ($$v) {
        _vm.drawer = $$v;
      },
      expression: "drawer"
    }
  }, [_c('v-list', {
    attrs: {
      "nav": ""
    }
  }, [_vm._l(_vm.menuItemsDisplayed, function (item) {
    return _c('v-list-item', {
      key: item.title,
      attrs: {
        "color": "primary",
        "data-cy": "navigation-item",
        "link": "",
        "to": item.link
      }
    }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v(_vm._s(item.icon) + " ")]), item.badgeKey && _vm.badges[item.badgeKey] ? _c('v-badge', {
      attrs: {
        "content": _vm.badges[item.badgeKey],
        "color": item.badgeColor
      }
    }) : _vm._e()], 1), _c('v-list-item-title', {
      staticClass: "text-overline"
    }, [_vm._v(_vm._s(item.title))])], 1);
  }), _c('v-btn', {
    staticClass: "text-overline",
    attrs: {
      "color": "primary",
      "block": ""
    },
    on: {
      "click": _vm.signOut
    }
  }, [_vm._v("Logg ut")])], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }