export function filterCoursesByStatuses(list: any, statuses: any, type: any) {
  const filteredList: any[] = [];
  if (type === "course") {
    statuses.filter((status: any) => {
      const filterStatusList = FilterCoursesByStatus(list, status);
      if (filterStatusList) {
        filterStatusList.filter((course: any) => filteredList.push(course));
      }
    });
  }
  if (type === "user") {
    statuses.filter((status: any) => {
      const filterStatusList = FilterCoursesByUserStatus(list, status);
      if (filterStatusList) {
        filterStatusList.filter((course: any) => filteredList.push(course));
      }
    });
  }
  const sortedCourses = filteredList.sort((a, b) => (a.courseId > b.courseId ? 1 : -1));
  return sortedCourses;
}

export function FilterCoursesByStatus(list: any, status: any) {
  if (!list) {
    return null;
  }
  if (list.length === 0) {
    return null;
  }
  if (!status) {
    return null;
  }
  const filteredList = list.filter((item: any) => {
    return item.course.status === status;
  });
  return filteredList;
}

export function FilterCoursesByUserStatus(list: any, status: any) {
  if (!list) {
    return null;
  }
  if (list.length === 0) {
    return null;
  }
  if (!status) {
    return null;
  }
  const filteredList = list.filter((item: any) => {
    return item.status === status;
  });
  return filteredList;
}
/* -------------------------------------------------------------- */
/* 
  Possible Todo:
  This used to be named translatedList, if my refactoring of it does not work
  then you can see its earlier version commented out below 
*/
export function FormatList(list: any, label: any) {
  const formattedList: any[] = [];
  list.forEach((item: any) => {
    const formattedItem = {
      text: item[label],
      value: item.value,
    };
    formattedList.push(formattedItem);
  });
  return formattedList;
}
/* export function translatedList(list, label, vm) {
  const translatedList = [];
  list.forEach((item) => {
    const translatedItem = {
      text: vm.$i18n.t(item[label]),
      value: item.value,
    };
    translatedList.push(translatedItem);
  });
  return translatedList;
} */
/* -------------------------------------------------------------- */
