import { EnvironmentVariables } from "@/environment";
import { getCourses, getRoles } from "@/api/course.api";
import { RootStoreState } from "@/store/root-store.state.interface";
import { NotificationItemInternal } from "@/shared/interfaces/notificationItemInternal.interface";
import { ActionTree, MutationTree, GetterTree } from "vuex";
import { HocActions } from "@/store/modules/hoc/hoc.actions.enum";
import { HocStoreState } from "@/store/modules/hoc/hoc.store.interface";
import { HocMutations } from "@/store/modules/hoc/hoc.mutations.enum";
import { NotificationItem } from "@/shared/interfaces/notificationItem.interface";
import { debounceHelper } from "@/shared/debounce";

const initialState: () => HocStoreState = () => ({
  modal: false,
  id: "",
  resource: false,
  resourceMode: false,
  snackbar: {
    isOpen: false,
    text: "",
    color: "",
  },
  loading: false,
  error: "",
  notifications: [],
  skeletonLoader: {
    loading: false,
    type: "",
  },
  showLoadingSpinner: false,
  ongoingBlockingRequests: 0,
  ongoingNonBlockingRequests: 0,
  ongoingTableSkeletonRequests: 0,
});

const state = initialState();

const getters = <GetterTree<HocStoreState, RootStoreState>>{
  getError(state: any) {
    return state.error;
  },
  getId(state: any) {
    return state.id;
  },
  getLoading(state: any) {
    return state.loading;
  },
  getResource(state: any) {
    return state.resource;
  },
  getResourceMode(state: any) {
    return state.resourceMode;
  },
};

const actions = <ActionTree<HocStoreState, RootStoreState>>{
  [HocActions.Reset]({ commit }) {
    commit(HocMutations.RESET);
  },
  [HocActions.SuccessSnackbar]({ commit }, response) {
    commit(HocMutations.OPEN_SNACKBAR, response);
  },
  [HocActions.ErrorSnackbar]({ commit }, response) {
    commit(HocMutations.ERROR_SNACKBAR, response);
  },
  [HocActions.LoadingSkeleton]({ commit }, response) {
    commit(HocMutations.SET_SKELETON_LOADING, response);
  },
  [HocActions.RemoveLoadingSkeleton]({ commit }) {
    commit(HocMutations.REMOVE_SKELETON_LOADING);
  },
  [HocActions.RemoveNotificationItem]({ commit }, notificationItem: NotificationItemInternal) {
    commit(HocMutations.REMOVE_NOTIFICATION_ITEM, notificationItem);
  },
  [HocActions.OpenNotification]({ commit }, notificationItem: NotificationItem) {
    const notificationInternal: NotificationItemInternal = {
      guid: Symbol(),
      type: notificationItem.type,
      message: notificationItem.message,
    };
    commit(HocMutations.APPEND_NOTIFICATION_ITEM, notificationInternal);
    if (notificationItem.timeOut && notificationItem.timeOut > 0) {
      setTimeout(() => {
        commit(HocMutations.REMOVE_NOTIFICATION_ITEM, notificationInternal.guid);
      }, notificationItem.timeOut);
    }
  },
  async [HocActions.FetchCustomerResource]({ commit }) {
    const roles = await getRoles();
    const courses = await getCourses();
    const resourceCourse = courses.data.find((course: any) => {
      const courseRole = course.roleName;
      return roles.data.find((role: any) => role.name === courseRole && role.isResource);
    });
    if (resourceCourse) {
      commit(HocMutations.SET_RESOURCE, true);
      commit(HocMutations.SET_RESOURCE_MODE, true);
    } else {
      commit(HocMutations.SET_RESOURCE, false);
    }
  },
  [HocActions.SetResourceMode]({ commit }, response) {
    commit(HocMutations.SET_RESOURCE_MODE, response);
  },
  [HocActions.SetLoading]() {
    // TODO implement global loader
  },
  [HocActions.RemoveLoading]() {
    // TODO implement global loader
  },
  async [HocActions.ChangeBlockingRequests]({ commit, state }, change: number) {
    commit(HocMutations.CHANGE_BLOCKING_REQUESTS, change);
    await debounceHelper.debounce(HocActions.ChangeBlockingRequests, EnvironmentVariables.HTTP_TIMEOUT + 10);
    if (state.ongoingBlockingRequests !== 0) {
      console.error("Blocking requests are out of sync!");
    }
  },
  async [HocActions.ChangeNonBlockingRequests]({ commit, state }, change: number) {
    commit(HocMutations.CHANGE_NON_BLOCKING_REQUESTS, change);
    await debounceHelper.debounce(HocActions.ChangeNonBlockingRequests, EnvironmentVariables.HTTP_TIMEOUT + 10);
    if (state.ongoingNonBlockingRequests !== 0) {
      console.error("Non blocking requests are out of sync!");
    }
  },
  async [HocActions.ChangeTableSkeletonRequests]({ commit, state }, change: number) {
    commit(HocMutations.CHANGE_TABLE_SKELETON_REQUESTS, change);
    await debounceHelper.debounce(HocActions.ChangeTableSkeletonRequests, EnvironmentVariables.HTTP_TIMEOUT + 10);
    if (state.ongoingNonBlockingRequests !== 0) {
      console.error("Table skeleton requests are out of sync!");
    }
  },
};

const mutations = <MutationTree<HocStoreState>>{
  [HocMutations.OPEN_SNACKBAR](state, response) {
    state.snackbar.isOpen = true;
    state.snackbar.text = `${response}`;
    state.snackbar.color = "primary";
  },
  [HocMutations.CLOSE_SNACKBAR](state) {
    state.snackbar.isOpen = null;
    state.snackbar.text = null;
    state.snackbar.color = null;
  },
  [HocMutations.ERROR_SNACKBAR](state, response) {
    state.snackbar.isOpen = true;
    state.snackbar.text = `Feil: ${response}`;
    state.snackbar.color = "danger";
  },
  [HocMutations.SET_SKELETON_LOADING](state, response) {
    state.skeletonLoader.loading = true;
    state.skeletonLoader.type = response;
  },
  [HocMutations.REMOVE_SKELETON_LOADING](state) {
    state.skeletonLoader.loading = null;
    state.skeletonLoader.type = null;
  },
  [HocMutations.APPEND_NOTIFICATION_ITEM](state, notification: NotificationItemInternal) {
    state.notifications.push(notification);
  },
  [HocMutations.REMOVE_NOTIFICATION_ITEM](state, notificationGuid: symbol) {
    state.notifications = state.notifications.filter((item: any) => item.guid !== notificationGuid);
  },
  [HocMutations.SET_SHOW_LOADING_SPINNER](state, showLoadingSpinner: boolean) {
    state.showLoadingSpinner = showLoadingSpinner;
  },
  [HocMutations.SET_RESOURCE](state, value) {
    state.resource = value;
  },
  [HocMutations.SET_RESOURCE_MODE](state, value) {
    state.resourceMode = value;
  },
  [HocMutations.CHANGE_BLOCKING_REQUESTS](state, change: number) {
    if (change > 1 || change < -1 || change === 0) {
      throw new Error("Invalid change value");
    }
    state.ongoingBlockingRequests = state.ongoingBlockingRequests += change;
  },
  [HocMutations.CHANGE_NON_BLOCKING_REQUESTS](state, change: number) {
    if (change > 1 || change < -1 || change === 0) {
      throw new Error("Invalid change value");
    }
    state.ongoingNonBlockingRequests = state.ongoingNonBlockingRequests += change;
  },
  [HocMutations.CHANGE_TABLE_SKELETON_REQUESTS](state, change: number) {
    if (change > 1 || change < -1 || change === 0) {
      throw new Error("Invalid change value");
    }
    state.ongoingTableSkeletonRequests = state.ongoingTableSkeletonRequests += change;
  },
};

export const HocModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
