export const HoursRouteList = [
  //  Working Hours for all courses
  {
    path: "/hours",
    name: "hours",
    component: () => import("@/pages/hours/HoursListPage.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { text: "Dashbord", disabled: false, href: "/" },
        {
          text: "Mine arbeidstimer",
          disabled: true,
          href: "/HoursList",
        },
      ],
    },
  },
  // Working hours for selected course
  {
    path: "/course/:id/hours",
    name: "course-hours-list",
    component: () => import("@/pages/hours/CourseHoursListPage.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { text: "Dashbord", disabled: false, href: "/" },
        {
          text: "Kurs",
          disabled: false,
          href: "/course",
        },
        {
          text: "Mine arbeidstimer",
          disabled: true,
          href: "",
        },
      ],
    },
  },
];
