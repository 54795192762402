var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.component ? _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "800px"
    },
    model: {
      value: _vm.isOpen,
      callback: function ($$v) {
        _vm.isOpen = $$v;
      },
      expression: "isOpen"
    }
  }, [_c(_vm.component, {
    tag: "component"
  })], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }