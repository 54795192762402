
import { defineComponent } from "@vue/composition-api";
import { mapState, mapMutations } from "vuex";

export default defineComponent({
  name: "TheSnackbar",
  computed: {
    ...mapState("hoc", {
      snackbar: (state: any) => state.snackbar,
    }),
    isLoading() {
      return (!!this.snackbar as any).isOpen;
    },
  },
  methods: {
    ...mapMutations("hoc", ["CLOSE_SNACKBAR"]),
    close() {
      this.CLOSE_SNACKBAR();
    },
  },
});
