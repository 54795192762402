import * as Msal from "@azure/msal-browser";
import { AuthenticationScheme } from "@azure/msal-browser";

class AuthService {
  private app: Msal.PublicClientApplication;
  private loginRequest: Msal.RedirectRequest;
  private isInitialized = false;
  public idToken?: string;

  constructor(config: Msal.Configuration) {
    this.app = new Msal.PublicClientApplication({
      ...config,
      auth: {
        redirectUri: new URL("/callback", location.origin).toString(),
        postLogoutRedirectUri: new URL("/", location.origin).toString(),
        ...config.auth,
      },
    });
    this.loginRequest = {
      authenticationScheme: AuthenticationScheme.BEARER,
      scopes: [config.auth.clientId],
    };
  }

  private async acquireTokenAsync() {
    const [account] = this.app.getAllAccounts();

    if (account) {
      this.app.setActiveAccount(account);
      return await this.app.acquireTokenSilent(this.loginRequest).catch((error) => {
        if (error instanceof Msal.InteractionRequiredAuthError || error instanceof Msal.BrowserAuthError) {
          return this.app.acquireTokenRedirect(this.loginRequest);
        }
      });
    }
    return await this.app.handleRedirectPromise();
  }

  public async initialize() {
    const tokenResponse = await this.acquireTokenAsync();

    this.idToken = tokenResponse ? tokenResponse?.idToken : undefined;

    this.isInitialized = true;
  }

  public async handleLoginRedirect() {
    await this.initialize();
    sessionStorage.setItem("redirectUri", window.location.pathname);
    if (!this.isAuthenticated) {
      await this.app.loginRedirect(this.loginRequest);
    }
  }

  public get isAuthenticated() {
    if (!this.isInitialized) {
      throw new Error("Attempted to get MSAL auth status before authService.initialize() has completed");
    }
    return !!this.idToken;
  }

  public async logout() {
    localStorage.clear();
    sessionStorage.clear();
    await this.app.logoutRedirect();
  }
}

export const authService = new AuthService({
  auth: {
    clientId: "f9de4dfa-070b-4d26-b714-fb6047a9b858",
    authority: "https://aofnorgeb2c.b2clogin.com/aofnorgeb2c.onmicrosoft.com/B2C_1_signup_login_v2",
    knownAuthorities: ["aofnorgeb2c.b2clogin.com"],
  },
});

export const editProfileInB2C = () => {
  const redirect = location.origin + "/callbackprofile";
  const url = `https://aofnorgeb2c.b2clogin.com/aofnorgeb2c.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_profile_editing_v2&client_id=f9de4dfa-070b-4d26-b714-fb6047a9b858&nonce=defaultNonce&redirect_uri=${redirect}&scope=openid&response_type=id_token&prompt=login`;
  window.open(url, "_blank");
};

export const updatePasswordInB2C = () => {
  const redirect = location.origin + "/callbackprofile";
  const url = `https://aofnorgeb2c.b2clogin.com/aofnorgeb2c.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_reset_password_v2&client_id=f9de4dfa-070b-4d26-b714-fb6047a9b858&nonce=defaultNonce&redirect_uri=${redirect}&scope=openid&response_type=id_token&prompt=login`;
  window.open(url, "_blank");
};
