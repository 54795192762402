export enum TestActions {
  Reset = "reset",
  PostCourse = "postCourse",
  PostInstructorCourse = "postInstructorCourse",
  PostCourseType = "postCourseType",
  PostPlannedCourse = "postPlannedCourse",
  PostFinishedCourse = "postFinishedCourse",
  PostCustomer = "postCustomer",
  PostCourseInitialAttendance = "postCourseInitialAttendance",
}
