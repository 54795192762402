export const AttendanceRouteList = [
  // // Student attendance for all courses
  {
    path: "/attendance",
    name: "attendance",
    component: () => import("@/pages/attendance/AttendanceListPage.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { text: "Dashbord", disabled: false, href: "/" },
        {
          text: "Fremmøteregistrering",
          disabled: true,
          href: "",
        },
      ],
    },
  },
  // // Student attendance for selected course
  {
    path: "/course/:id/attendance",
    name: "course-attendance-list",
    component: () => import("@/pages/attendance/AttendanceDayPage.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { text: "Dashbord", disabled: false, href: "/" },
        {
          text: "Kurs",
          disabled: false,
          href: "/course",
        },
        {
          text: "Fremmøteregistrering",
          disabled: true,
          href: "",
        },
      ],
    },
  },
  // Student attendance for selected course on given day
  {
    path: "/course/:id/attendance/:dayId",
    name: "course-attendance-day",
    component: () => import("@/pages/attendance/AttendanceDayPage.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { text: "Dashbord", disabled: false, href: "/" },
        {
          text: "Kurs",
          disabled: false,
          href: "/course",
        },
        {
          text: "Fremmøteregistrering",
          disabled: false,
          href: "/course/:id/attendance",
        },
        {
          text: "Frammøteliste for valgt dagen",
          disabled: true,
          href: "",
        },
      ],
    },
  },
];
