
import { computed, defineComponent, ref } from "@vue/composition-api";
import { authService } from "@/authService";
import { useRouter } from "@/shared/useHelpers";

export default defineComponent({
  name: "AuthGuard",
  emits: ["authenticated"],
  setup(props, { emit }) {
    const router = useRouter();
    const isRouterReady = ref(false);
    const isAuthenticated = ref(false);
    const requiresAuth = computed(() => !!router.currentRoute.meta?.requiresAuth);

    router.onReady(async () => {
      isRouterReady.value = true;

      await authService.initialize();

      isAuthenticated.value = authService.isAuthenticated;

      if (isAuthenticated.value) {
        emit("authenticated");
      }
    });

    return {
      isRouterReady,
      isAuthenticated,
      requiresAuth,
    };
  },
});
