import { api } from "@/api/api";
import { CustomerActions } from "@/store/modules/customer/customer.actions.enum";
import { CustomerMutations } from "@/store/modules/customer/customer.mutations.enum";
import { CustomerStoreState } from "@/store/modules/customer/customer.store.interface";
import { RootStoreState } from "@/store/root-store.state.interface";
import { ActionTree, MutationTree } from "vuex";
import { getField, updateField } from "vuex-map-fields";

const initialState: () => CustomerStoreState = () => ({
  signupCompleted: null,
  customer: undefined,
});

const state = initialState();

const getters = {
  getField,
  getCust(state: CustomerStoreState) {
    return state.customer;
  },
  getAddress(state: any) {
    return state.customer.addresses[0];
  },
  getSignupState(state: CustomerStoreState) {
    return state.signupCompleted;
  },
};

const actions = <ActionTree<CustomerStoreState, RootStoreState>>{
  async [CustomerActions.FetchCustomer]({ commit, dispatch }) {
    try {
      dispatch("hoc/setLoading", null, { root: true });
      const response = await api.minside.getCustomerPersonCurrentAsync();
      localStorage.setItem("customer", response.data.id.toString());
      dispatch("hoc/removeLoading", null, { root: true });
      commit(CustomerMutations.SET_CUSTOMER, response.data);
      commit(CustomerMutations.SET_SIGNUP_STATE, response.data.mobileNumber);
    } catch (error) {
      console.error(error);
    }
  },
  [CustomerActions.SetSignupCompleted]({ commit }) {
    commit(CustomerMutations.SET_SIGNUP_STATE, true);
  },
  /* FIX NAME OF THIS FUNCTION */
  async [CustomerActions.UpdateCustomer]() {
    throw new Error("Not implemented, need to use B2C");
    // try {
    //   dispatch("hoc/setLoading", null, { root: true });
    //   const requestBody: Partial<ApiGetCustomerDto> = state.customer || {};
    //   delete requestBody.insertedBy;
    //   delete requestBody.insertDate;
    //   delete requestBody.insertedByUser;
    //   delete requestBody.updateDate;
    //   delete requestBody.updatedBy;
    //   delete requestBody.updatedByUser;
    //   const response = await updatecustomer(requestBody, JSON.parse(localStorage.getItem("customer") as any));
    //   dispatch("hoc/removeLoading", null, { root: true });
    //   /* Need to find a solution to the response object as it is not a string */
    //   /*  openNotification(this as any, NotificationItemType.Success, response); */
    //   dispatch("hoc/setSnackbar", response, { root: true });
    // } catch (error) {
    //   console.error(error);
    // }
  },
};

const mutations = <MutationTree<CustomerStoreState>>{
  updateField,
  [CustomerMutations.SET_BIRTHDATE](state, value) {
    if (state.customer && state.customer.customer.birthDate !== value) {
      state.customer.customer.birthDate = value;
    }
  },
  [CustomerMutations.SET_CUSTOMER](state, data) {
    state.customer = data;
  },
  [CustomerMutations.SET_SIGNUP_STATE](state, data) {
    state.signupCompleted = data ? true : false;
  },
};

export const CustomerModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
