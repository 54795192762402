export enum FileActions {
  Reset = "reset",
  FetchFileCategories = "fetchFileCategories",
  FetchFileEntities = "fetchFileEntities",
  FetchCoursesFiles = "fetchCoursesFiles",
  FetchFiles = "fetchFiles",
  ViewFile = "viewFile",
  UploadFile = "uploadFile",
  Stringify = "stringify",
  DownloadFile = "downloadFile",
  DeleteFile = "deleteFile",
  SetTemporaryFile = "setTemporaryFile",
}
