var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "display": "contents"
    }
  }, _vm._l(_vm.list, function (notification, index) {
    return _c('v-list-item', {
      key: index,
      attrs: {
        "two-line": "",
        "data-cy": "notificationsItem"
      },
      on: {
        "click": function ($event) {
          return _vm.notificationLink(notification.route, notification.id);
        }
      }
    }, [_c('v-list-item-content', [_c('TheNotificationTitle', {
      attrs: {
        "item": notification
      }
    })], 1)], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }