import { securedHttpClient } from "@/api/httpClient";

export const getAllCourses = () => securedHttpClient.get(`/course`);

export const getCourses = () => {
  return securedHttpClient.get(`/course/participant/self`);
};

export const getCourse = (courseid: any) => securedHttpClient.get(`/minside/course/${courseid}`);

export function getCourseOrganizer(organizerId: any) {
  return { data: organizerId };
}

export const getCourseArea = (location: any) => securedHttpClient.get(`/area/${location.areaId}`);

export const getCourseLocation = (location: any) => securedHttpClient.get(`/area/${location.areaId}/location`);

export const getCourseRoom = (location: any) =>
  securedHttpClient.get(`/area/${location.areaId}/location/${location.locationId}/room/${location.roomId}`);

export const getCourseParticipants = (courseid: any) => securedHttpClient.get(`/course/${courseid}/participant`);

export const updateCourseParticipant = (courseid: any, payload: any) =>
  securedHttpClient.post(`/course/${courseid}/participant`, payload);

export const getCourseStatuses = () => {
  return {
    status: 200,
    data: ["planlagt", "åpnet", "utsatt", "innstilt", "avsluttet", "slettet"],
  };
};

export const getAllowedCourseStatuses = () => {
  return {
    status: 200,
    data: ["planlagt", "åpnet", "utsatt"],
  };
};

export const getCourseParticipantStatuses = () => {
  return {
    status: 200,
    data: ["interessert", "påmeldt", "søkt", "avmeldt"],
  };
};

export const getRoles = () => securedHttpClient.get(`/role`);
