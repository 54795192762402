import { TestActions } from "@/store/modules/test/test.actions.enum";
import { TestMutations } from "@/store/modules/test/test.mutations.enum";

import {
  postTeachingMaterial,
  postCurriculum,
  updateCurriculum,
  postExecutionPlan,
  postArea,
  postLocation,
  postRoom,
  postCourse,
  updateCourse,
  postCustomer,
  postParticipant,
  postTestType,
} from "@/api/test.api";
import {
  getCourse,
  getCourseParticipants,
  getAllowedCourseStatuses,
  getCourseParticipantStatuses,
  getRoles,
} from "@/api/course.api";
import { generateString } from "@/shared/utils/test";
import { TestStoreState } from "@/store/modules/test/test.store.interface";
import { RootStoreState } from "@/store/root-store.state.interface";
import { ActionTree, MutationTree } from "vuex";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { openNotification } from "@/shared/helpers/store.helpers";

const initialState: () => TestStoreState = () => ({
  test: {},
});

const state = initialState();

const getters = {};

const actions = <ActionTree<TestStoreState, RootStoreState>>{
  [TestActions.Reset]({ commit }) {
    commit(TestMutations.RESET);
  },
  async [TestActions.PostCourse]({ dispatch }, customerId) {
    try {
      dispatch("hoc/setLoading", null, { root: true });
      const name = `Test Course ${generateString(5)}`;
      const startDate = -1;
      const endDate = startDate + 16;
      const courseStatuses = await getAllowedCourseStatuses();
      const status = courseStatuses.data[Math.floor(Math.random() * Math.floor(courseStatuses.data.length))];
      const roles = await getRoles();
      const participantRole = roles.data.find((role: any) => role.isResource === false);
      const participantStatuses = await getCourseParticipantStatuses();
      const teachingMaterial = await postTeachingMaterial();
      const curriculum = await postCurriculum(teachingMaterial.data.id);
      await updateCurriculum(curriculum.data.id);
      await postExecutionPlan(curriculum.data.id);
      const area = await postArea();
      const location = await postLocation(area.data.id);
      const room = await postRoom(area.data.id, location.data.id);
      const contact = await postCustomer();
      const lecturer = await postCustomer();
      const supervisor = await postCustomer();
      const testType = await postTestType();
      const course = await postCourse(
        name,
        startDate,
        endDate,
        status,
        curriculum.data.id,
        area.data.id,
        location.data.id,
        room.data.id,
        contact.data.id,
        lecturer.data.id,
        supervisor.data.id,
        teachingMaterial.data.id,
        testType.data.id
      );
      await postParticipant(course.data.id, customerId, participantRole.name, "påmeldt");
      for (let i = 0; i <= 10; i++) {
        const participant = await postCustomer();
        const participantStatus =
          participantStatuses.data[Math.floor(Math.random() * Math.floor(participantStatuses.data.length))];
        await postParticipant(course.data.id, participant.data.id, participantRole.name, participantStatus);
      }
      dispatch(
        "test/postCourseInitialAttendance",
        {
          customerId: customerId,
          courseId: course.data.id,
        },
        {
          root: true,
        }
      );
      dispatch("hoc/removeLoading", null, { root: true });
      /* Need to find a solution to the course object as it is not a string */
      /*  openNotification(this as any, NotificationItemType.Success, course); */
      dispatch("hoc/setSnackbar", course, { root: true });
    } catch (error: any) {
      dispatch("hoc/removeLoading", null, { root: true });
      openNotification(this as any, NotificationItemType.Error, error);
      console.error(error);
    }
  },
  async [TestActions.PostInstructorCourse]({ dispatch }, customerId) {
    try {
      dispatch("hoc/setLoading", null, { root: true });
      const name = `Test Course ${generateString(5)}`;
      const startDate = -1;
      const endDate = startDate + 16;
      const courseStatuses = await getAllowedCourseStatuses();
      const status = courseStatuses.data[Math.floor(Math.random() * Math.floor(courseStatuses.data.length))];
      const roles = await getRoles();
      const instructorRole = roles.data.find((role: any) => role.isResource === true && role.name === "Lærer");
      const participantRole = roles.data.find((role: any) => role.isResource === false);
      const participantStatuses = await getCourseParticipantStatuses();
      const teachingMaterial = await postTeachingMaterial();
      const curriculum = await postCurriculum(teachingMaterial.data.id);
      await updateCurriculum(curriculum.data.id);
      await postExecutionPlan(curriculum.data.id);
      const area = await postArea();
      const location = await postLocation(area.data.id);
      const room = await postRoom(area.data.id, location.data.id);
      const contact = await postCustomer();
      const lecturer = await postCustomer();
      const testType = await postTestType();
      const course = await postCourse(
        name,
        startDate,
        endDate,
        status,
        curriculum.data.id,
        area.data.id,
        location.data.id,
        room.data.id,
        contact.data.id,
        lecturer.data.id,
        customerId,
        teachingMaterial.data.id,
        testType.data.id
      );
      await postParticipant(course.data.id, customerId, instructorRole.name, "påmeldt");
      for (let i = 0; i <= 10; i++) {
        const participant = await postCustomer();
        const participantStatus =
          participantStatuses.data[Math.floor(Math.random() * Math.floor(participantStatuses.data.length))];
        await postParticipant(course.data.id, participant.data.id, participantRole.name, participantStatus);
      }
      dispatch(
        "test/postCourseInitialAttendance",
        {
          customerId: customerId,
          courseId: course.data.id,
        },
        {
          root: true,
        }
      );
      dispatch("hoc/removeLoading", null, { root: true });
      /* Need to find a solution to the course object as it is not a string */
      /*  openNotification(this as any, NotificationItemType.Success, course); */
      dispatch("hoc/setSnackbar", course, { root: true });
    } catch (error: any) {
      dispatch("hoc/removeLoading", null, { root: true });
      openNotification(this as any, NotificationItemType.Success, error);
      dispatch("hoc/setSnackbar", error, { root: true });
      console.error(error);
    }
  },
  async [TestActions.PostCourseType]({ dispatch }, payload) {
    try {
      dispatch("hoc/setLoading", null, { root: true });
      const name = `Test Course ${generateString(5)}`;
      const startDate = -1;
      const endDate = startDate + 16;
      const roles = await getRoles();
      const participantRole = roles.data.find((role: any) => role.isResource === false);
      const participantStatuses = await getCourseParticipantStatuses();
      const teachingMaterial = await postTeachingMaterial();
      const curriculum = await postCurriculum(teachingMaterial.data.id);
      await updateCurriculum(curriculum.data.id);
      await postExecutionPlan(curriculum.data.id);
      const area = await postArea();
      const location = await postLocation(area.data.id);
      const room = await postRoom(area.data.id, location.data.id);
      const contact = await postCustomer();
      const lecturer = await postCustomer();
      const supervisor = await postCustomer();
      const testType = await postTestType();
      const course = await postCourse(
        name,
        startDate,
        endDate,
        payload.status,
        curriculum.data.id,
        area.data.id,
        location.data.id,
        room.data.id,
        contact.data.id,
        lecturer.data.id,
        supervisor.data.id,
        teachingMaterial.data.id,
        testType.data.id
      );
      await postParticipant(course.data.id, payload.customerId, participantRole.name, "påmeldt");
      for (let i = 0; i <= 10; i++) {
        const participant = await postCustomer();
        const participantStatus =
          participantStatuses.data[Math.floor(Math.random() * Math.floor(participantStatuses.data.length))];
        await postParticipant(course.data.id, participant.data.id, participantRole.name, participantStatus);
      }
      dispatch(
        "test/postCourseInitialAttendance",
        {
          customerId: payload.customerId,
          courseId: course.data.id,
        },
        {
          root: true,
        }
      );
      dispatch("hoc/removeLoading", null, { root: true });
      /* Need to find a solution to the course object as it is not a string */
      /*  openNotification(this as any, NotificationItemType.Success, course); */
      dispatch("hoc/setSnackbar", course, { root: true });
    } catch (error: any) {
      dispatch("hoc/removeLoading", null, { root: true });
      openNotification(this as any, NotificationItemType.Error, error);
      console.error(error);
    }
  },
  async [TestActions.PostPlannedCourse]({ dispatch }, customerId) {
    try {
      dispatch("hoc/setLoading", null, { root: true });
      const name = `Test Planned Course ${generateString(5)}`;
      const startDate = +30;
      const endDate = startDate + 16;
      const status = "planlagt";
      const roles = await getRoles();
      const participantRole = roles.data.find((role: any) => role.isResource === false);
      const teachingMaterial = await postTeachingMaterial();
      const curriculum = await postCurriculum(teachingMaterial.data.id);
      await updateCurriculum(curriculum.data.id);
      await postExecutionPlan(curriculum.data.id);
      const area = await postArea();
      const location = await postLocation(area.data.id);
      const room = await postRoom(area.data.id, location.data.id);
      const contact = await postCustomer();
      const lecturer = await postCustomer();
      const supervisor = await postCustomer();
      const testType = await postTestType();
      const course = await postCourse(
        name,
        startDate,
        endDate,
        status,
        curriculum.data.id,
        area.data.id,
        location.data.id,
        room.data.id,
        contact.data.id,
        lecturer.data.id,
        supervisor.data.id,
        teachingMaterial.data.id,
        testType.data.id
      );
      if (customerId) {
        await postParticipant(course.data.id, customerId, participantRole.name, "påmeldt");
        dispatch(
          "test/postCourseInitialAttendance",
          {
            customerId: customerId,
            courseId: course.data.id,
          },
          {
            root: true,
          }
        );
      }
      dispatch("hoc/removeLoading", null, { root: true });
      /* Need to find a solution to the course object as it is not a string */
      /*  openNotification(this as any, NotificationItemType.Success, course); */
      dispatch("hoc/setSnackbar", course, { root: true });
    } catch (error: any) {
      dispatch("hoc/removeLoading", null, { root: true });
      openNotification(this as any, NotificationItemType.Error, error);
      console.error(error);
    }
  },
  async [TestActions.PostFinishedCourse]({ dispatch }, customerId) {
    try {
      dispatch("hoc/setLoading", null, { root: true });
      const name = `Test Finished Course ${generateString(5)}`;
      const startDate = -30;
      const endDate = startDate + 16;
      const status = "åpnet";
      const statusUpdate = "avsluttet";
      const updateReason = "Oppdatering av test kurs";
      const roles = await getRoles();
      const instructorRole = roles.data.find((role: any) => role.isResource === true && role.name === "Lærer");
      const participantRole = roles.data.find((role: any) => role.isResource === false);
      const participantStatuses = await getCourseParticipantStatuses();
      const teachingMaterial = await postTeachingMaterial();
      const curriculum = await postCurriculum(teachingMaterial.data.id);
      await updateCurriculum(curriculum.data.id);
      await postExecutionPlan(curriculum.data.id);
      const area = await postArea();
      const location = await postLocation(area.data.id);
      const room = await postRoom(area.data.id, location.data.id);
      const contact = await postCustomer();
      const lecturer = await postCustomer();
      const testType = await postTestType();
      const newCourse = await postCourse(
        name,
        startDate,
        endDate,
        status,
        curriculum.data.id,
        area.data.id,
        location.data.id,
        room.data.id,
        contact.data.id,
        lecturer.data.id,
        customerId,
        teachingMaterial.data.id,
        testType.data.id
      );
      await postParticipant(newCourse.data.id, customerId, instructorRole.name, "påmeldt");
      for (let i = 0; i <= 10; i++) {
        const participant = await postCustomer();
        const participantStatus =
          participantStatuses.data[Math.floor(Math.random() * Math.floor(participantStatuses.data.length))];
        await postParticipant(newCourse.data.id, participant.data.id, participantRole.name, participantStatus);
      }
      dispatch(
        "test/postCourseInitialAttendance",
        {
          customerId: customerId,
          courseId: newCourse.data.id,
        },
        {
          root: true,
        }
      );
      const courseResponse = await getCourse(newCourse.data.id);
      const course = courseResponse.data;
      const updatedCourse = await updateCourse(
        newCourse.data.id,
        course.curriculumId,
        course.courseName,
        statusUpdate,
        updateReason,
        course.startDate,
        course.endDate,
        course.startsAt.hour,
        course.endsAt.hour,
        course.hoursWithInstructor,
        course.hoursWithoutInstructor,
        course.organizerOrganizationId,
        course.ownerOrganizationId,
        course.courseSupervisorId,
        course.lecturerId,
        course.contactId,
        course.courseLocation.areaId,
        course.courseLocation.locationId,
        course.courseLocation.roomId,
        course.cancellationDeadline,
        course.enrollmentDeadline,
        course.financedByOrganizationId,
        course.hoursSelfStudy,
        course.minimumParticipants,
        course.maxParticipants,
        course.plan,
        course.additionalTeachingMaterialIds,
        course.tests
      );
      dispatch("hoc/removeLoading", null, { root: true });
      /* Need to find a solution to the course object as it is not a string */
      /*  openNotification(this as any, NotificationItemType.Success, course); */
      dispatch("hoc/setSnackbar", updatedCourse, { root: true });
    } catch (error: any) {
      dispatch("hoc/removeLoading", null, { root: true });
      openNotification(this as any, NotificationItemType.Error, error);
      console.error(error);
    }
  },
  async [TestActions.PostCustomer]({ dispatch }) {
    try {
      dispatch("hoc/setLoading", null, { root: true });
      await postCustomer();
      dispatch("hoc/removeLoading", null, { root: true });
    } catch (error) {
      dispatch("hoc/removeLoading", null, { root: true });
      console.error(error);
    }
  },
  async [TestActions.PostCourseInitialAttendance]({ dispatch }, payload) {
    try {
      dispatch("hoc/setLoading", null, { root: true });
      const datesList: any[] = [];
      const participantsList: any[] = [];
      const course = await getCourse(payload.courseId);
      if (course.status === 200) {
        course.data.plan.schedules.forEach((date: any) => datesList.push(date.start));
      }
      const participants = await getCourseParticipants(payload.courseId);
      if (participants.status === 200) {
        participants.data.forEach((participant: any) => {
          if (participant.roleName === "Student") {
            const item = {
              customerId: participant.userId,
              registeredHours: 0,
            };
            participantsList.push(item);
          }
        });
      }
      if (datesList.length > 0) {
        datesList.forEach((date) => {
          const initialAttendanceRequest = {
            courseId: payload.courseId,
            date: date,
            list: participantsList,
          };
          dispatch("attendance/postAttendance", initialAttendanceRequest, {
            root: true,
          });
        });
      }
      dispatch("hoc/removeLoading", null, { root: true });
    } catch (error) {
      dispatch("hoc/removeLoading", null, { root: true });
      console.error(error);
    }
  },
};

const mutations = <MutationTree<TestStoreState>>{
  //  Will always add a reset mutation so we can use the gloabal reset.
  [TestMutations.RESET](state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key as keyof TestStoreState] = newState[key as keyof TestStoreState];
    });
  },
};

export const TestModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
