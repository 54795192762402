export const ProfileRouteList = [
  // //  Profile
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/pages/minside/ProfilePage.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { text: "Dashbord", disabled: false, href: "/" },
        {
          text: "Bruker profil",
          disabled: true,
          href: "/profile",
        },
      ],
    },
  },
];
