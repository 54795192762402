export enum HocActions {
  Reset = "reset",
  SuccessSnackbar = "successSnackbar",
  ErrorSnackbar = "errorSnackbar",
  LoadingSkeleton = "loadingSkeleton",
  RemoveLoadingSkeleton = "removeLoadingSkeleton",
  RemoveNotificationItem = "removeNotificationItem",
  OpenNotification = "openNotification",
  FetchCustomerResource = "fetchCustomerResource",
  SetResourceMode = "setResourceMode",
  SetLoading = "setLoading",
  RemoveLoading = "removeLoading",
  ChangeBlockingRequests = "changeBlockingRequests",
  ChangeNonBlockingRequests = "ChangeNonBlockingRequests",
  ChangeTableSkeletonRequests = "ChangeTableSkeletonRequests",
}
