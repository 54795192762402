export enum StoreModules {
  Global = "global",
  Todo = "todo",
  Attendance = "attendance",
  Course = "course",
  Customer = "customer",
  Expenses = "expenses",
  File = "file",
  Grades = "grades",
  Hoc = "hoc",
  Hours = "hours",
  Login = "login",
  Messages = "messages",
  Notifications = "notifications",
  Register = "register",
  Signin = "signin",
  Test = "test",
}
