var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    attrs: {
      "offset-y": "",
      "open-delay": "60",
      "close-delay": "100",
      "content-class": "rounded",
      "close-on-content-click": false,
      "left": "",
      "bottom": "",
      "max-width": "320"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('span', _vm._g(_vm._b({
          staticClass: "v-app-tooltip-btn d-inline-block"
        }, 'span', attrs, false), on), [_c('v-tooltip', {
          attrs: {
            "bottom": "",
            "open-delay": "100"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref2) {
              var on = _ref2.on,
                attrs = _ref2.attrs;
              return [_c('v-avatar', _vm._g(_vm._b({
                attrs: {
                  "color": "primary",
                  "rounded": "",
                  "size": "31"
                }
              }, 'v-avatar', attrs, false), on), [_c('span', {
                staticClass: "white--text"
              }, [_vm._v(_vm._s(_vm.customerShortName))])])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Profil")])])], 1)];
      }
    }])
  }, [_c('v-list', {
    attrs: {
      "expand": "",
      "nav": ""
    }
  }, [_c('v-list-item', {
    staticClass: "v-list-item--default",
    attrs: {
      "to": "/profile",
      "color": "primary"
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-account ")])], 1), _c('v-list-item-title', {
    staticClass: "enter-profile text-overline"
  }, [_vm._v(" Profil ")])], 1), _c('v-list-item', {
    staticClass: "v-list-item--default",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.signOut
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-exit-to-app ")])], 1), _c('v-list-item-title', {
    staticClass: "enter-profile text-overline"
  }, [_vm._v(" Logg ut ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }