<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "TheNotificationTitle",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isRead() {
      if (this.item.isRead) {
        return "";
      }
      return "font-weight-bold";
    },
  },
});
</script>
<template>
  <div style="display: contents">
    <v-list-item-title :class="isRead">
      {{ item.title }}
    </v-list-item-title>
    <v-list-item-subtitle :class="isRead">
      {{ item.subtTitle }}
    </v-list-item-subtitle>
  </div>
</template>
