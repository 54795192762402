import Vue from "vue";
import App from "@/App.vue";
import "@/registerServiceWorker";
import router from "@/router/router";
import store from "@/store/root.store";
// @ts-ignore TODO move to TS
import Dialog from "@/plugins/dialog";
import vuetify from "@/plugins/vuetify";
import i18n from "@/plugins/i18n";
import "@/plugins/composition-api";
import LogRocket from "logrocket";
import { EnvironmentVariables } from "./environment";

import { createVPhoneInput } from "v-phone-input";
import "v-phone-input/dist/v-phone-input.css";
import "flag-icons/css/flag-icons.min.css";
import VueGtm from "@gtm-support/vue2-gtm";

if (EnvironmentVariables.IN_PRODUCTION_ENV) {
  LogRocket.init("xacocm/aof-minside", {
    release: `Git: ${EnvironmentVariables.APP_REV}`,
    console: {
      shouldAggregateConsoleErrors: true,
    },
    // TODO network requestSanitizer & responseSanitizer
    shouldDebugLog: EnvironmentVariables.IN_DEV_ENV,
  });
}

// disable all service workers for now
if (window.navigator && navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    for (const registration of registrations) {
      registration.unregister();
    }
  });
}

const vPhoneInput = createVPhoneInput({
  countryIconMode: "svg",
  displayFormat: "international",
  label: "Mobilnummer",
  countryLabel: "Land",
  invalidMessage: ({ label, example }) => `${label} må være et gyldig nummer (eksempel: ${example}).`,
});

Vue.use(vPhoneInput);

Vue.config.productionTip = false;

Vue.use(Dialog);

// Google tag manager setup
Vue.use(VueGtm, {
  id: EnvironmentVariables.GTM_ID,
  defer: false,
  enabled: true,
  debug: false,
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false,
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
