export const HelpPageRouteList = [
  // HelpPage

  {
    path: "/helppage",
    name: "HelpPage",
    component: () => import("@/components/shell/app-router/TheHelpPage.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { text: "Dashbord", disabled: false, href: "/" },
        {
          text: "Hjelpeside",
          disabled: true,
          href: "/helppage",
        },
      ],
    },
  },
];
