export enum CourseActions {
  Reset = "reset",
  FetchInitialCourses = "fetchInitialCourses",
  FetchAllCourses = "fetchAllCourses",
  FetchCourses = "fetchCourses",
  FetchFinishedCourses = "fetchFinishedCourses",
  FetchFormCourse = "fetchFormCourse",
  FetchCourse = "fetchCourse",
  FetchOrganizer = "fetchOrganizer",
  FetchSupervisor = "fetchSupervisor",
  FetchLecturer = "fetchLecturer",
  FetchContact = "fetchContact",
  FetchArea = "fetchArea",
  FetchLocation = "fetchLocation",
  FetchRoom = "fetchRoom",
  FetchParticipants = "fetchParticipants",
  FetchParticipant = "fetchParticipant",
  FetchCalendarCourses = "fetchCalendarCourses",
  FetchStatuses = "fetchStatuses",
  FetchParticipantStatuses = "fetchParticipantStatuses",
  FetchRoles = "fetchRoles",
  SetSelectedCourseAssignments = "setSelectedCourseAssignments",
}
