var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', {
    staticClass: "background-primary-color"
  }, [_vm.ongoingNonBlockingRequests ? _c('v-progress-linear', {
    attrs: {
      "height": "6",
      "indeterminate": "",
      "color": "yellow darken-2"
    }
  }) : _vm._e(), _c('AuthGuard', {
    on: {
      "authenticated": _vm.handleAuthenticated
    }
  }, [_vm.signupCompleted ? _c('TheDrawer') : _vm._e(), !_vm.isMobile ? _c('TheNavIcons') : _vm._e(), _c('v-overlay', {
    attrs: {
      "value": _vm.showLoadingSpinner || _vm.ongoingBlockingRequests > 0,
      "z-index": "205"
    }
  }, [_c('v-progress-circular', {
    staticClass: "loader",
    attrs: {
      "size": 200,
      "width": 10,
      "color": "primary",
      "indeterminate": ""
    }
  })], 1), _vm.ongoingTableSkeletonRequests > 0 ? _c('div', {
    class: _vm.isMobile ? 'pt-12' : 'ma-4'
  }, [_c('TheSkeletonLoader', {
    attrs: {
      "type": "table"
    }
  })], 1) : _vm._e(), _c('TheModalContainer'), _c('TheSnackbar'), _c('v-main', {
    class: _vm.isMobile ? 'pt-14' : 'pa-0'
  }, [_c('router-view', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.ongoingTableSkeletonRequests === 0,
      expression: "ongoingTableSkeletonRequests === 0"
    }]
  })], 1), _c('div', {
    staticClass: "notificationSection"
  }, [_c('TheNotificationDisplayer')], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }