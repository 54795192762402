var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    attrs: {
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": "",
            "data-cy": "notificationButton"
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-bell")])], 1)];
      }
    }])
  }, [_c('v-list', {
    attrs: {
      "data-cy": "notificationsList"
    }
  }, [_vm.emptyNotifications ? _c('TheEmptyNotification') : _c('TheNotificationsList', {
    attrs: {
      "list": _vm.getNotifications
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }