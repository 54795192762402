import { AttendanceRouteList } from "@/router/routes/attendanceRouteList";
import { CoursesRouteList } from "@/router/routes/coursesRouteList";
import { DocumentsRouteList } from "@/router/routes/documentsRouteList";
import { HoursRouteList } from "@/router/routes/hoursRouteList";
import { ProfileRouteList } from "@/router/routes/profileRouteList";
import { MessagesRouteList } from "./routes/messagesRouteList";
import { HelpPageRouteList } from "./routes/helppageRouteList";

export const ApplicationRoutes = [
  //  Login
  {
    path: "/callback",
    name: "LoginCallback",
    component: () => import("@/pages/login/LoginCallback.vue"),
  },
  {
    path: "/callbackprofile",
    name: "TheProfileCallback",
    component: () => import("@/pages/minside/ProfileCallback.vue"),
  },
  {
    path: "/completeSignup",
    name: "SignupPage",
    component: () => import("@/pages/login/SignupPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  //  Application
  {
    path: "/",
    name: "dashboard",
    component: () => import("@/pages/dashboard/DashboardPage.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ text: "Dashboard", disabled: true, href: "/" }],
    },
  },
  ...AttendanceRouteList,
  ...CoursesRouteList,
  ...DocumentsRouteList,
  ...HoursRouteList,
  ...ProfileRouteList,
  ...MessagesRouteList,
  ...HelpPageRouteList,
  {
    path: "/apply/:courseId",
    name: "courseApply",
    component: () => import("@/pages/ApplyCoursePage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import(/* webpackChunkName: "PageNotFoundPage" */ "../pages/PageNotFoundPage.vue"),
  },
];
