import { getExpenses } from "@/api/expenses.api";
import { RootStoreState } from "@/store/root-store.state.interface";
import { ActionTree, MutationTree } from "vuex";
import { ExpensesStoreState } from "@/store/modules/expenses/expenses.store.interface";
import { ExpensesActions } from "@/store/modules/expenses/expenses.actions.enum";
import { ExpensesMutations } from "@/store/modules/expenses/expenses.mutations.enum";

const initialState: () => ExpensesStoreState = () => ({
  expenses: [],
});

const state = initialState();

const getters = {
  getExpenses(state: any) {
    return state.expenses;
  },
};

const actions = <ActionTree<ExpensesStoreState, RootStoreState>>{
  [ExpensesActions.Reset]({ commit }) {
    commit(ExpensesMutations.RESET);
  },
  async [ExpensesActions.FetchExpenses]({ commit }, customerId) {
    try {
      const response = await getExpenses(customerId);
      commit(ExpensesMutations.SET_EXPENSES, response.data);
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = <MutationTree<ExpensesStoreState>>{
  //  Will always add a reset mutation so we can use the global reset.
  [ExpensesMutations.RESET](state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key as keyof ExpensesStoreState] = newState[key as keyof ExpensesStoreState];
    });
  },
  [ExpensesMutations.SET_EXPENSES](state, data) {
    state.expenses = data;
  },
};

export const ExpensesModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
