import { EnvironmentVariables } from "@/environment";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { NotificationItem } from "@/shared/interfaces/notificationItem.interface";
import axios, { AxiosError } from "axios";
import { authService } from "@/authService";
import { openNotification } from "@/shared/helpers/store.helpers";
import { Store } from "vuex";
import { StoreState } from "@/store/store.state.interface";
import rootStore from "@/store/root.store";

const securedHttpClient = axios.create({
  baseURL: EnvironmentVariables.VUE_APP_SEC_API_URL,
  timeout: 30000, // indicates, 1000ms ie. 1 second
  headers: {
    "Cache-Control": "no-cache",
    "Ocp-Apim-Subscription-Key": EnvironmentVariables.VUE_APP_API_OCP_KEY,
    "User-ID": sessionStorage.getItem("user-token-expires-in"),
  },
  paramsSerializer: (params) =>
    // Provides proper support for parameter serializing of arrays
    formatRequestParams(params),
});

const formatRequestParams = (params: any) => {
  const urlParam = new URLSearchParams();
  for (const property in params) {
    if (params[property] === undefined) {
      // for undefined just skip
      continue;
    }
    if (Array.isArray(params[property])) {
      params[property].forEach((element: string) => {
        urlParam.append(property, element);
      });
    } else {
      urlParam.append(property, params[property]);
    }
  }
  return urlParam.toString();
};

const kursAdminHttpClient = axios.create({
  baseURL: process.env.VUE_APP_SEC_KURS_API_URL,
  timeout: 30000, // indicates, 1000ms ie. 1 second
  headers: {
    "Cache-Control": "no-cache",
    "Ocp-Apim-Subscription-Key": process.env.VUE_APP_SEC_KURS_API_CODE,
  },
});

// Interceptor for requests
const requestInterceptor = async (config: any) => {
  const token = sessionStorage.getItem("cypress-token") ?? authService.idToken;

  config.headers["Authorization"] = `Bearer ${token}`;

  return config;
};

// Interceptor for responses
const responseInterceptor = (response: any) => {
  switch (response.status) {
    case 200:
      // yay!
      break;
    // any other cases
    default:
    // default case
  }

  return response;
};

const displaySimpleValidationErrors = (store: Store<StoreState>, responseData: any) => {
  if (Array.isArray(responseData)) {
    responseData.forEach((errorItem: any) => {
      if (typeof errorItem === "string") {
        openNotification(store, NotificationItemType.Warning, errorItem, 8000);
      }
    });
  } else if (typeof responseData === "string") {
    openNotification(store, NotificationItemType.Warning, responseData, 8000);
  }
};

// interceptor to catch errors
const errorInterceptor = (error: AxiosError) => {
  const store = rootStore as unknown as Store<StoreState>;
  const error400Message = "En valideringsfeil oppsto (400)";
  const error401Message = "Du har ikke tilgang (401)";
  const error404Message = "Ressursen som ble forespurt finnes ikke (404)";
  const errorGeneralMessage = "En systemfeil oppsto. Vi er i gang med å undersøke problemet";

  if (!error.response) {
    return;
  }
  const errorNotification: NotificationItem = {
    message: "",
    timeOut: 5000,
    type: NotificationItemType.Error,
  };
  if (error.response.status >= 500) {
    errorNotification.message = errorGeneralMessage;
  } else {
    switch (error.response.status) {
      case 400:
        errorNotification.message = error400Message;
        if (error.response.data) {
          displaySimpleValidationErrors(store, error.response.data);
        }
        break;
      case 401: // authentication error, logout the user
        errorNotification.message = error401Message;
        break;
      case 404: {
        errorNotification.message = error404Message;
        break;
      }
      default:
        errorNotification.message = errorGeneralMessage;
    }
  }
  store.dispatch("hoc/openNotification", errorNotification);
  return Promise.reject(error);
};

securedHttpClient.interceptors.request.use(requestInterceptor, errorInterceptor);
securedHttpClient.interceptors.response.use(responseInterceptor, errorInterceptor);

kursAdminHttpClient.interceptors.request.use(requestInterceptor, errorInterceptor);
kursAdminHttpClient.interceptors.response.use(responseInterceptor, errorInterceptor);

export { securedHttpClient, kursAdminHttpClient };
