var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-snackbar', {
    attrs: {
      "color": _vm.snackbar.color
    },
    model: {
      value: _vm.snackbar.isOpen,
      callback: function ($$v) {
        _vm.$set(_vm.snackbar, "isOpen", $$v);
      },
      expression: "snackbar.isOpen"
    }
  }, [_vm._v(" " + _vm._s(_vm.snackbar.text) + " "), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" Lukk ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }