import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import no from "vuetify/src/locale/no";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#094c51",
        secondary: "#74a680",
        accent: "#c9dd0a",
        error: "#ff5252",
        info: "#2196f3",
        success: "#4caf50",
        warning: "#fb8c00",
      },
    },
  },
  lang: {
    locales: { no },
    current: "no",
  },
});
