import { authService } from "@/authService";
import { EnvironmentVariables } from "@/environment";
import { ApplicationRoutes } from "@/router/routes-list";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: EnvironmentVariables.BASE_URL,
  routes: ApplicationRoutes,
});

//  Router logic
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    authService.handleLoginRedirect();
  }

  next();
});

export default router;
