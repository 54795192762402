export enum CourseMutations {
  RESET = "RESET",
  SET_ALL_COURSES = "SET_ALL_COURSES",
  SET_COURSES = "SET_COURSES",
  SET_ACTIVE_COURSES = "SET_ACTIVE_COURSES",
  SET_FINISHED_COURSES = "SET_FINISHED_COURSES",
  SET_COURSE = "SET_COURSE",
  SET_ORGANIZER = "SET_ORGANIZER",
  SET_AREA = "SET_AREA",
  SET_LOCATION = "SET_LOCATION",
  SET_ROOM = "SET_ROOM",
  SET_SUPERVISOR = "SET_SUPERVISOR",
  SET_LECTURER = "SET_LECTURER",
  SET_CONTACT = "SET_CONTACT",
  SET_PARTICIPANTS = "SET_PARTICIPANTS",
  SET_CALENDAR_COURSES = "SET_CALENDAR_COURSES",
  SET_STATUSES = "SET_STATUSES",
  SET_PARTICIPANT_STATUSES = "SET_PARTICIPANT_STATUSES",
  SET_ROLES = "SET_ROLES",
  SET_USER_ROLE = "SET_USER_ROLE",
  SET_SELECTED_COURSE_ASSIGNMENTS = "SET_SELECTED_COURSE_ASSIGNMENTS",
}
