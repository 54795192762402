import { getCourseAttendanceList, getDayList, postAttendance } from "@/api/attendance.api";
import { getCourse, getCourses } from "@/api/course.api";
import { FormatDate } from "@/shared/helpers/dateHelpers";
import { RootStoreState } from "@/store/root-store.state.interface";
import { ActionTree, MutationTree } from "vuex";
import { getField, updateField } from "vuex-map-fields";
import { AttendanceStoreState } from "@/store/modules/attendance/attendance.store.interface";
import { openNotification } from "@/shared/helpers/store.helpers";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { AttendanceActions } from "@/store/modules/attendance/attendance.actions.enum";
import { AttendanceMutations } from "@/store/modules/attendance/attendance.mutations.enum";

const initialState: () => AttendanceStoreState = () => ({
  attendanceList: [],
  courseAttendanceList: [],
  attendance: {},
  dayList: [],
  currentDay: "",
  participantAttendance: {},
});

const state = initialState();

const getters = {
  getField,
  getAttendanceList(state: any) {
    return state.attendanceList;
  },
  getCourseAttendanceList(state: any) {
    return state.courseAttendanceList;
  },
  getDayList(state: any) {
    return state.dayList;
  },
  getCurrentDay(state: any) {
    return state.currentDay;
  },
  getParticipantAttendance(state: any) {
    return state.participantAttendance;
  },
};

const actions = <ActionTree<AttendanceStoreState, RootStoreState>>{
  [AttendanceActions.Reset]({ commit }) {
    commit(AttendanceMutations.RESET);
  },
  async [AttendanceActions.FetchAttendanceList]({ commit, dispatch }) {
    try {
      dispatch("hoc/setLoading", null, { root: true });
      const status = (attendanceList: any, participantsList: any, date: any) => {
        if (attendanceList.length === 0) {
          return 0;
        }
        const dayAttendance = attendanceList.filter((day: any) => {
          return day.scheduleStart === date;
        });
        const participationFactor = (dayAttendance.length / participantsList.length) * 100;
        return participationFactor;
      };
      const coursesIds: any[] = [];
      const courses = await getCourses();
      courses.data.forEach((course: any) => {
        coursesIds.push(course.courseId);
      });
      const attendancePlans: any[] = [];
      // Get plans for all courses
      for (let i = 0; i < coursesIds.length; i++) {
        const courseParticipants = await getDayList(coursesIds[i]);
        const courseParticipantIdList: any[] = [];
        const courseParticipationList: any[] = [];

        // Get list of participants ids
        courseParticipants.data.forEach((participant: any) => {
          courseParticipantIdList.push(participant.userId);
        });

        const participation = await getCourseAttendanceList(coursesIds[i]);
        // Get list of participations in a course
        participation.data.forEach((item: any) => {
          courseParticipationList.push(item);
        });
        const courseAttendancePlan = await getCourse(coursesIds[i]);
        courseAttendancePlan.data.plan.schedules.forEach((item: any, index: any) => {
          const attendanceItem = item;
          attendanceItem.courseDay = index;
          attendanceItem.courseName = courseAttendancePlan.data.courseName;
          attendanceItem.courseId = coursesIds[i];
          attendanceItem.completed = status(courseParticipationList, courseParticipantIdList, attendanceItem.start);
          attendancePlans.push(attendanceItem);
        });
      }
      dispatch("hoc/removeLoading", null, { root: true });
      commit(AttendanceMutations.SET_ATTENDANCE_LIST, attendancePlans);
    } catch (error) {
      console.error(error);
    }
  },
  async [AttendanceActions.FetchCourseAttendanceList]({ commit, dispatch }, courseId) {
    try {
      dispatch("hoc/setLoading", null, { root: true });
      const response = await getCourseAttendanceList(courseId);
      dispatch("hoc/removeLoading", null, { root: true });
      commit(AttendanceMutations.SET_COURSE_ATTENDANCE_LIST, response.data);
    } catch (error) {
      console.error(error);
    }
  },
  /* MOVE COURSES STORE */
  /* async [AttendanceActions.FetchDayList]({ commit, dispatch }, payload) {
    try {
      dispatch("hoc/setLoading", null, { root: true });
      let customerIds:any[] = [];
      const date = this.state.course.course.plan.schedules[payload.dayId].start;
      const response = await getDayList(payload.courseId);
      const attendance = await getCourseAttendanceList(payload.courseId);
      response.data.forEach((item:any) => {
        customerIds.push(item.userId);
      });
      // Place customer into relevant array item
      for (let i = 0; i < customerIds.length; i++) {
        const customer = await getCustomer(customerIds[i]);
        const idIndex = response.data.findIndex((item:any) => {
          return item.userId === customerIds[i];
        });
        response.data[idIndex].customer = customer.data.customer;
      }
      // Place hours and comments into customer relevant array item - outline for future function
      for (let i = 0; i < response.data.length; i++) {
        let hours = "";
        let comments = "";
        const hoursIndex = attendance.data.findIndex((item) => {
          return item.userId === response.data[i].userId && item.scheduleStart === date;
        });
        hours = undefined;
        if (hoursIndex > -1) {
          hours = attendance.data[hoursIndex].noOfHours;
        }
        response.data[i].hours = hours;
        response.data[i].comments = comments;
      }
      dispatch("hoc/removeLoading", null, { root: true });
      commit(AttendanceMutations.SET_DAY_ATTENDANCE_LIST, response.data);
    } catch (error) {
      console.error(error);
    }
  }, */
  async [AttendanceActions.PostAttendance]({ dispatch, commit }, payload) {
    if (payload.list.length === 0) {
      return;
    }
    try {
      dispatch("hoc/setLoading", null, { root: true });
      const requestBody: {
        courseId: any;
        scheduleStart: number;
        participantAttendances: any[];
      } = {
        courseId: payload.courseId,
        scheduleStart: payload.date,
        participantAttendances: [],
      };
      payload.list.forEach((participant: any) => {
        const filteredParticipant = {
          userId: participant.customerId,
          noOfHours: participant.registeredHours,
        };
        requestBody.participantAttendances.push(filteredParticipant);
      });
      let response = undefined;
      if (requestBody.participantAttendances.length > 0) {
        response = await postAttendance(requestBody);
      }
      if (response && response.status === 204) {
        commit(AttendanceMutations.SET_DAY_ATTENDANCE_ITEM, requestBody.participantAttendances);
      }
      dispatch("hoc/removeLoading", null, { root: true });
      openNotification(this as any, NotificationItemType.Success, "Success");
    } catch (error) {
      dispatch("hoc/removeLoading", null, { root: true });
      console.error(error);
    }
  },
  async [AttendanceActions.UpdateCurrentDay]({ commit }, date) {
    const filteredDate = FormatDate(date);
    commit(AttendanceMutations.SET_CURRENT_DAY, filteredDate);
  },
};

const mutations = <MutationTree<AttendanceStoreState>>{
  updateField,
  //  Will always add a reset mutation so we can use the global reset.
  [AttendanceMutations.RESET](state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key as keyof AttendanceStoreState] = newState[key as keyof AttendanceStoreState];
    });
  },
  [AttendanceMutations.SET_ATTENDANCE_LIST](state, data) {
    state.attendanceList = data;
  },
  [AttendanceMutations.SET_COURSE_ATTENDANCE_LIST](state, { days: days }) {
    state.courseAttendanceList = days;
  },
  [AttendanceMutations.SET_DAY_ATTENDANCE_LIST](state, data) {
    state.dayList = data;
  },
  [AttendanceMutations.SET_DAY_ATTENDANCE_ITEM](state, data) {
    state.dayList.forEach((item) => {
      const updatedIndex = data.findIndex((record: any) => record.userId === item.userId);
      if (updatedIndex > -1) {
        item.hours = data[updatedIndex].noOfHours;
      }
    });
    return state.dayList;
  },
  [AttendanceMutations.SET_CURRENT_DAY](state, data) {
    state.currentDay = data;
  },
};

export const AttendanceModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
