export enum FileMutations {
  RESET = "RESET",
  SET_CATEGORIES = "SET_CATEGORIES",
  SET_ENTITIES = "SET_ENTITIES",
  SET_COURSES = "SET_COURSES",
  SET_FILES = "SET_FILES",
  SET_FILE = "SET_FILE",
  SET_FILE_PREVIEW = "SET_FILE_PREVIEW",
  DOWNLOAD_FILE = "DOWNLOAD_FILE",
  SET_TEMPORARY_FILE = "SET_TEMPORARY_FILE",
}
