class DebounceHelper {
  #elements = new Map<string, number>();
  #lastId = 0;
  /** Do await debounceHelper("label", 500) at start of function you want to debounce  */
  debounce(label: string, ms = 500) {
    const myId = ++this.#lastId;
    this.#elements.set(label, myId);
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        if (myId === this.#elements.get(label)) {
          this.#elements.delete(label);
          resolve();
        }
      }, ms);
    });
  }
}

export const debounceHelper = new DebounceHelper();
