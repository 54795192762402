import { RootStoreState } from "@/store/root-store.state.interface";
import { ActionTree, MutationTree } from "vuex";
import { updateField } from "vuex-map-fields";
import { GlobalStoreState } from "@/store/modules/global/global.store.interface";
import { GlobalActions } from "@/store/modules/global/global.actions.enum";
import { api } from "@/api/api";
import { GlobalMutations } from "@/store/modules/global/global.mutations.enum";

const initialState: () => GlobalStoreState = () => ({
  countries: [],
});

const state = initialState();

const getters = {
  getCountries(state: any) {
    return state.countries;
  },
};

const actions = <ActionTree<GlobalStoreState, RootStoreState>>{
  async [GlobalActions.FetchCountries]({ commit, dispatch }) {
    try {
      const res = (await api.ssb.getCountriesAsync()).data;
      dispatch("hoc/removeLoading", null, { root: true });
      commit(GlobalMutations.SET_COUNTRIES, res);
    } catch (error) {
      dispatch("hoc/removeLoading", null, { root: true });
      console.error(error);
    }
  },
};

const mutations = <MutationTree<GlobalStoreState>>{
  updateField,
  [GlobalMutations.SET_COUNTRIES](state, data) {
    state.countries = data;
  },
};

export const GlobalModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
