import { api } from "@/api/api";
import {
  getAllCourses,
  getCourse,
  getCourseArea,
  getCourseLocation,
  getCourseOrganizer,
  getCourseParticipants,
  getCourseParticipantStatuses,
  getCourseRoom,
  getCourses,
  getCourseStatuses,
  getRoles,
  updateCourseParticipant,
} from "@/api/course.api";
import { getCustomerKompetanse } from "@/api/login.api";
import { formatCourseDates, FormatHour, formatLocalizedDate } from "@/shared/helpers/dateHelpers";
import { filterCoursesByStatuses } from "@/shared/helpers/filterHelpers";
import { randomNumber } from "@/shared/helpers/otherHelpers";
import { CourseType } from "@/shared/types/course.types";
import { CalendarColors } from "@/shared/utils/colors";
import { CourseActions } from "@/store/modules/course/course.actions.enum";
import { CourseMutations } from "@/store/modules/course/course.mutations.enum";
import { CourseStoreState } from "@/store/modules/course/course.store.interface";
import { RootStoreState } from "@/store/root-store.state.interface";
import { ActionTree, MutationTree } from "vuex";

const initialState: () => CourseStoreState = () => ({
  allCourses: [],
  courses: [],
  coursesFinished: [],
  course: null,
  organizer: {},
  area: {},
  location: [],
  room: {},
  contact: {},
  supervisor: {},
  lecturer: {},
  participants: [],
  calendarSchedule: [],
  statuses: [],
  participantStatuses: [],
  roles: [],
  userRole: "Student",
  coursesActive: [],
  selectedCourseAssignments: <CourseType>{},
  participantSubscribedCount: 0,
});

const state = initialState();

const getters = {
  getAllCourses(state: any) {
    return state.allCourses;
  },
  getCourses(state: any) {
    return state.courses;
  },
  getFinishedCourses(state: any) {
    return state.coursesFinished;
  },
  getCourse(state: any) {
    return state.course;
  },
  getOrganizer(state: any) {
    return state.organizer;
  },
  getArea(state: any) {
    return state.area;
  },
  getLocation(state: any) {
    return state.location;
  },
  getRoom(state: any) {
    return state.room;
  },
  getStaff(state: any) {
    const staff: any[] = [];
    const staffTypes = ["contact", "supervisor", "lecturer"];
    staffTypes.forEach((type) => {
      state[type].type = type;
      staff.push(state[type]);
    });
    return staff;
  },
  getParticipants(state: any) {
    return state.participants;
  },
  getCalendarCourses(state: any) {
    return state.calendarSchedule;
  },
  getStatuses(state: any) {
    return state.statuses;
  },
  getparticipantStatuses(state: any) {
    return state.participantStatuses;
  },
  getRoles(state: any) {
    return state.roles;
  },
  getUserRole(state: any) {
    return state.userRole;
  },
};

const actions = <ActionTree<CourseStoreState, RootStoreState>>{
  [CourseActions.Reset]({ commit }) {
    commit(CourseMutations.RESET);
  },
  async [CourseActions.FetchInitialCourses]({ dispatch }) {
    dispatch("hoc/setLoading", null, { root: true });
    const response = await api.course.getCourseParticipantAndCoursesByCurrentUserAsync();
    dispatch("hoc/removeLoading", null, { root: true });
    return response;
  },
  async [CourseActions.FetchAllCourses]({ commit }) {
    try {
      const response = await getAllCourses();
      commit(CourseMutations.SET_ALL_COURSES, response.data);
    } catch (error) {
      console.error(error);
    }
  },
  async [CourseActions.FetchCourses]({ commit, dispatch }) {
    try {
      const response = await dispatch("fetchInitialCourses");
      if (response.status === 200) {
        const allowedCourseStatuses = ["åpnet", "planlagt"];
        const filteredCourses = filterCoursesByStatuses(response.data, allowedCourseStatuses, "course");
        commit(CourseMutations.SET_COURSES, filteredCourses);
      }
    } catch (error) {
      dispatch("hoc/removeLoading", null, { root: true });
      console.error(error);
    }
  },
  async [CourseActions.FetchFinishedCourses]({ commit, dispatch }) {
    try {
      const response = await dispatch("course/fetchInitialCourses");
      const allowedCourseStatus = ["avsluttet"];
      const finishedCourses = filterCoursesByStatuses(response.data, allowedCourseStatus, "course");
      commit(CourseMutations.SET_FINISHED_COURSES, finishedCourses);
    } catch (error) {
      console.error(error);
    }
  },
  async [CourseActions.FetchFormCourse]({ commit, dispatch }, payload) {
    try {
      dispatch("hoc/setLoading", null, { root: true });
      const response = await getCourse(payload.courseId);
      if (!response.data) {
        dispatch("hoc/removeLoading", null, { root: true });
        return;
      }
      if (response.data && response.data.organizerOrganizationId) {
        dispatch("course/fetchOrganizer", response.data.organizerOrganizationId, { root: true });
      }
      if (response.data && response.data.courseLocation) {
        dispatch("course/fetchArea", response.data.courseLocation, {
          root: true,
        });
        dispatch("course/fetchLocation", response.data.courseLocation, {
          root: true,
        });
      }
      dispatch("hoc/removeLoading", null, { root: true });
      commit(CourseMutations.SET_COURSE, response.data);
    } catch (error) {
      dispatch("hoc/removeLoading", null, { root: true });
      console.error(error);
    }
  },
  async [CourseActions.FetchCourse]({ commit, dispatch }, payload) {
    try {
      dispatch("hoc/setLoading", null, { root: true });
      commit(CourseMutations.SET_PARTICIPANTS, []);
      const response = (await api.minside.getCourseByIdAsync(payload.courseId)).data;
      if (response) {
        dispatch("course/fetchOrganizer", response.organizerOrganizationId, { root: true });
        dispatch("course/fetchSupervisor", response.courseSupervisorId, {
          root: true,
        });
        dispatch("course/fetchLecturer", response.lecturerId, {
          root: true,
        });
        dispatch("course/fetchContact", response.contactId, {
          root: true,
        });
      }
      if (response.courseLocation) {
        dispatch("course/fetchArea", response.courseLocation, {
          root: true,
        });
        dispatch("course/fetchLocation", response.courseLocation, {
          root: true,
        });
      }
      dispatch("hoc/removeLoading", null, { root: true });
      commit(CourseMutations.SET_COURSE, response);
    } catch (error) {
      dispatch("hoc/removeLoading", null, { root: true });
      console.error(error);
    }
  },
  async [CourseActions.FetchOrganizer]({ commit }, organizerId) {
    try {
      const response = await getCourseOrganizer(organizerId);
      commit(CourseMutations.SET_ORGANIZER, response.data);
    } catch (error) {
      console.error(error);
    }
  },
  async [CourseActions.FetchSupervisor]({ commit }, supervisorId) {
    try {
      const response = await getCustomerKompetanse(supervisorId);
      commit("SET_SUPERVISOR", response.data);
    } catch (error) {
      console.error(error);
    }
  },
  async [CourseActions.FetchLecturer]({ commit }, lecturerId) {
    try {
      const response = await getCustomerKompetanse(lecturerId);
      commit("SET_LECTURER", response.data);
    } catch (error) {
      console.error(error);
    }
  },
  async [CourseActions.FetchContact]({ commit }, contactId) {
    try {
      const response = await getCustomerKompetanse(contactId);
      commit("SET_CONTACT", response.data);
    } catch (error) {
      console.error(error);
    }
  },
  async [CourseActions.FetchArea]({ commit }, locationId) {
    try {
      const response = await getCourseArea(locationId);
      commit(CourseMutations.SET_AREA, response.data);
    } catch (error) {
      console.error(error);
    }
  },
  async [CourseActions.FetchLocation]({ commit }, locationId) {
    try {
      const response = await getCourseLocation(locationId);
      commit(CourseMutations.SET_LOCATION, response.data);
    } catch (error) {
      console.error(error);
    }
  },
  async [CourseActions.FetchRoom]({ commit }, locationId) {
    try {
      const response = await getCourseRoom(locationId);
      commit(CourseMutations.SET_ROOM, response.data);
    } catch (error) {
      console.error(error);
    }
  },
  async [CourseActions.FetchParticipant]({ commit }, payload) {
    try {
      const userId = payload.userId;
      const response = await getCourseParticipants(payload.courseId);
      const participant = response.data.filter((participant: any) => {
        return participant.userId === userId;
      });
      commit(CourseMutations.SET_PARTICIPANTS, participant);
    } catch (error) {
      console.error(error);
    }
  },
  async [CourseActions.FetchCalendarCourses]({ commit, dispatch }) {
    try {
      dispatch("hoc/setLoading", null, { root: true });
      const coursesIds: any[] = [];
      const courses = await getCourses();
      const allowedCourseStatuses = ["planlagt", "åpnet"];
      const allowedUserStatuses = ["påmeldt"];
      const filteredCoursesByStatus = filterCoursesByStatuses(courses.data, allowedCourseStatuses, "course");
      const filteredCoursesByUserStatus = filterCoursesByStatuses(filteredCoursesByStatus, allowedUserStatuses, "user");
      filteredCoursesByUserStatus.forEach((course) => {
        coursesIds.push(course.courseId);
      });
      const plans: any[] = [];
      // Get plans for all courses
      for (let i = 0; i < coursesIds.length; i++) {
        const course = await getCourse(coursesIds[i]);
        const courseColor = CalendarColors[randomNumber(0, CalendarColors.length - 1)];
        course.data.plan.schedules.forEach((item: any) => {
          item.courseName = course.data.courseName;
          item.courseId = coursesIds[i];
          item.color = courseColor;
          plans.push(item);
        });
      }
      commit(CourseMutations.SET_CALENDAR_COURSES, plans);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch("hoc/removeLoading", null, { root: true });
    }
  },
  async [CourseActions.FetchStatuses]({ commit }) {
    try {
      const response = await getCourseStatuses();
      if (response.status === 200) {
        const statuses: any = [];
        response.data.forEach((item) => {
          const status = {
            text: "",
            value: item,
          };
          switch (item) {
            case "planlagt":
              status.text = "table.courses.statuses.planned";
              break;
            case "åpnet":
              status.text = "table.courses.statuses.opened";
              break;
            case "utsatt":
              status.text = "table.courses.statuses.postponed";
              break;
            case "innstilt":
              status.text = "table.courses.statuses.cancelled";
              break;
            case "avsluttet":
              status.text = "table.courses.statuses.completed";
              break;
            default:
              status.text = "table.courses.statuses.deleted";
              break;
          }
          statuses.push(status);
        });
        commit(CourseMutations.SET_STATUSES, statuses);
      }
    } catch (error) {
      console.error(error);
    }
  },
  async [CourseActions.FetchParticipantStatuses]({ commit }) {
    try {
      const response = await getCourseParticipantStatuses();
      commit(CourseMutations.SET_PARTICIPANT_STATUSES, response.data);
    } catch (error) {
      console.error(error);
    }
  },
  async [CourseActions.FetchRoles]({ commit }) {
    try {
      const response = await getRoles();
      commit(CourseMutations.SET_ROLES, response.data);
    } catch (error) {
      console.error(error);
    }
  },
  async postParticipant(notInUse, payload) {
    try {
      const request = {
        status: payload.status,
        userId: payload.userId,
        roleName: payload.roleName,
      };
      await updateCourseParticipant(payload.courseId, request);
    } catch (error) {
      console.error(error);
    }
  },
  async [CourseActions.SetSelectedCourseAssignments]({ commit }, payload) {
    commit(CourseMutations.SET_SELECTED_COURSE_ASSIGNMENTS, payload);
  },
};

const mutations = <MutationTree<CourseStoreState>>{
  //  Will always add a reset mutation so we can use the gloabal reset.
  [CourseMutations.RESET](state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key as keyof CourseStoreState] = newState[key as keyof CourseStoreState];
    });
  },
  [CourseMutations.SET_ALL_COURSES](state, data) {
    state.allCourses = data;
  },
  [CourseMutations.SET_COURSES](state, data) {
    const formattedDates = formatCourseDates(data);
    state.courses = formattedDates;
  },
  /* ADDED coursesActive manually to the state, it was not there before */
  [CourseMutations.SET_ACTIVE_COURSES](state, data) {
    const formattedDates = formatCourseDates(data);
    state.coursesActive = formattedDates;
  },
  [CourseMutations.SET_FINISHED_COURSES](state, data) {
    const formattedDates = formatCourseDates(data);
    state.coursesFinished = formattedDates;
  },
  [CourseMutations.SET_COURSE](state, data) {
    data.cancellationDeadline = !data.cancellationDeadline ? null : formatLocalizedDate(data.cancellationDeadline);
    data.enrollmentDeadline = !data.enrollmentDeadline ? null : formatLocalizedDate(data.enrollmentDeadline);

    // The times needs to be formatted first before being overridden by the date formatting
    data.endTime = FormatHour(data.endDate);
    data.startTime = FormatHour(data.startDate);

    data.endDate = formatLocalizedDate(data.endDate);
    data.startDate = formatLocalizedDate(data.startDate);
    state.course = data;
  },
  [CourseMutations.SET_ORGANIZER](state, data) {
    state.organizer = data;
  },
  [CourseMutations.SET_AREA](state, data) {
    state.area = data;
  },
  [CourseMutations.SET_LOCATION](state, data) {
    state.location = data;
  },
  [CourseMutations.SET_ROOM](state, data) {
    state.room = data;
  },
  [CourseMutations.SET_SUPERVISOR](state, data) {
    state.supervisor = data;
  },
  [CourseMutations.SET_LECTURER](state, data) {
    state.lecturer = data;
  },
  [CourseMutations.SET_CONTACT](state, data) {
    state.contact = data;
  },
  [CourseMutations.SET_PARTICIPANTS](state, data) {
    state.participants = data;
  },
  [CourseMutations.SET_CALENDAR_COURSES](state, data) {
    state.calendarSchedule = data;
  },
  [CourseMutations.SET_STATUSES](state, data) {
    state.statuses = data;
  },
  [CourseMutations.SET_PARTICIPANT_STATUSES](state, data) {
    state.participantStatuses = data;
  },
  [CourseMutations.SET_ROLES](state, data) {
    state.roles = data;
  },
  [CourseMutations.SET_USER_ROLE](state, data) {
    state.userRole = data;
  },
  [CourseMutations.SET_SELECTED_COURSE_ASSIGNMENTS](state, data) {
    state.selectedCourseAssignments = data;
  },
};

export const CourseModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
