
import { defineComponent } from "@vue/composition-api";
import TheNotificationTitle from "@/components/notifications/TheNotificationTitle.vue";

export default defineComponent({
  name: "NotificationList",
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  components: { TheNotificationTitle },
});
