export enum HocMutations {
  RESET = "RESET",
  SET_LANGUAGE = "SET_LANGUAGE",
  OPEN_SNACKBAR = "OPEN_SNACKBAR",
  CLOSE_SNACKBAR = "CLOSE_SNACKBAR",
  ERROR_SNACKBAR = "ERROR_SNACKBAR",
  SET_SKELETON_LOADING = "SET_SKELETON_LOADING",
  REMOVE_SKELETON_LOADING = "REMOVE_SKELETON_LOADING",
  APPEND_NOTIFICATION_ITEM = "APPEND_NOTIFICATION_ITEM",
  REMOVE_NOTIFICATION_ITEM = "REMOVE_NOTIFICATION_ITEM",
  SET_RESOURCE = "SET_RESOURCE",
  SET_RESOURCE_MODE = "SET_RESOURCE_MODE",
  CHANGE_BLOCKING_REQUESTS = "CHANGE_BLOCKING_REQUESTS",
  CHANGE_NON_BLOCKING_REQUESTS = "CHANGE_NON_BLOCKING_REQUESTS",
  CHANGE_TABLE_SKELETON_REQUESTS = "CHANGE_TABLE_SKELETON_REQUESTS",
  SET_SHOW_LOADING_SPINNER = "SET_SHOW_LOADING_SPINNER",
}
