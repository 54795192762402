import Vue from "vue";
import { VDialog } from "vuetify/lib";

const dialog = ({ style, component, parent, ...props }) => {
  const okFns = [],
    cancelFns = [],
    API = {
      onOk(fn) {
        okFns.push(fn);
        return API;
      },
      onCancel(fn) {
        cancelFns.push(fn);
        return API;
      },
      onClose(fn) {
        okFns.push(fn);
        cancelFns.push(fn);
        return API;
      },
      hide() {
        vm.hide();
        return API;
      },
    };

  const el = document.createElement("div");
  document.body.appendChild(el);

  const cleanup = () => {
    vm.$destroy();
    vm.$el.remove();
    vm = null;
  };

  let emittedOk = false;

  Vue.observable(props);

  let vm = new Vue({
    name: "Dialog",
    parent,
    el,
    data() {
      return { show: false };
    },
    methods: {
      hide() {
        this.onInput(false);
      },
      onInput(show) {
        this.show = show;
        if (!show) {
          this.onHide();
        }
      },
      onOk(data) {
        emittedOk = true;
        okFns.forEach((f) => f(data));
      },
      onHide() {
        if (!emittedOk) {
          cancelFns.forEach((f) => f());
        }
        cleanup();
      },
    },
    mounted() {
      this.show = true;
    },
    render(h) {
      const content = h(component, {
        props,
        on: {
          ok: this.onOk,
          hide: this.hide,
        },
      });
      return h(
        VDialog,
        {
          style,
          props: {
            width: "fit-content",
            value: this.show,
          },
          on: {
            input: this.onInput,
          },
        },
        [content]
      );
    },
  });
  return API;
};

export default {
  install(Vue) {
    Vue.prototype.$dialog = dialog;
  },
};
