export const DocumentsRouteList = [
  // documents
  {
    path: "/documents",
    name: "documents",
    component: () => import("@/pages/minside/documents/MinsideDocumentPage.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { text: "Dashbord", disabled: false, href: "/" },
        {
          text: "Dokumenter",
          disabled: false,
          href: "/documents",
        },
        {
          text: "Bruker",
          disabled: true,
        },
      ],
    },
  },
];
