import { securedHttpClient } from "@/api/httpClient";

export const getMessages = (customerid: any, messagetype: any) => {
  if (messagetype == "inbox") {
    return {
      status: 200,
      data: [
        {
          id: 0,
          time: "12 Juli",
          title: "Inbox this weekend?",
          author: "Ali Inboxer",
          body: "I'll be in your neighborhood doing errands this weekend. Do you want to hang out?",
          isRead: false,
        },
        {
          id: 1,
          time: "20 Juni",
          title: "Summer BBQ",
          author: "me, Scrott, Jennifer",
          body: "Wish I could come, but I'm out of town this weekend.",
          isRead: false,
        },
        {
          id: 2,
          time: "12 Juni",
          title: "Oui oui",
          author: "Sandra Adams",
          body: "Do you have Paris recommendations? Have you ever been?",
          isRead: false,
        },
        {
          id: 3,
          time: "12 Mai",
          title: "Birthday gift",
          author: "Trevor Hansen",
          body: "Have any ideas about what we should get Heidi for her birthday?",
          isRead: false,
        },
        {
          id: 4,
          time: "18 April",
          title: "Recipe to try",
          author: "Trevor Hansen",
          body: "We should eat this: Grate, Squash, Corn, and tomatillo Tacos.",
          isRead: false,
        },
        {
          id: 5,
          time: "12 Mai",
          title: "Birthday gift",
          author: "Trevor Hansen",
          body: "Have any ideas about what we should get Heidi for her birthday?",
          isRead: false,
        },
        {
          id: 6,
          time: "18 April",
          title: "Recipe to try",
          author: "Trevor Hansen",
          body: "We should eat this: Grate, Squash, Corn, and tomatillo Tacos.",
          isRead: false,
        },
        {
          id: 7,
          time: "12 Mai",
          title: "Birthday gift",
          author: "Trevor Hansen",
          body: "Have any ideas about what we should get Heidi for her birthday?",
          isRead: false,
        },
        {
          id: 8,
          time: "18 April",
          title: "Recipe to try",
          author: "Trevor Hansen",
          body: "We should eat this: Grate, Squash, Corn, and tomatillo Tacos.",
          isRead: false,
        },
        {
          id: 9,
          time: "12 Mai",
          title: "Birthday gift",
          author: "Trevor Hansen",
          body: "Have any ideas about what we should get Heidi for her birthday?",
          isRead: false,
        },
        {
          id: 10,
          time: "18 April",
          title: "Recipe to try",
          author: "Trevor Hansen",
          body: "We should eat this: Grate, Squash, Corn, and tomatillo Tacos.",
          isRead: false,
        },
      ],
    };
    /* 
    securedHttpClient.get("/message/inbox", {
      params: {
        customerId: customerid
      }
    });*/
  }
  return {
    status: 200,
    data: [
      {
        id: 0,
        time: "12 Juli",
        title: "Outbox this weekend?",
        author: "Ali Outboxer",
        body: "I'll be in your neighborhood doing errands this weekend. Do you want to hang out?",
        isRead: true,
      },
      {
        id: 1,
        time: "20 Juni",
        title: "Summer BBQ",
        author: "me, Scrott, Jennifer",
        body: "Wish I could come, but I'm out of town this weekend.",
        isRead: true,
      },
      {
        id: 2,
        time: "12 Juni",
        title: "Oui oui",
        author: "Sandra Adams",
        body: "Do you have Paris recommendations? Have you ever been?",
        isRead: true,
      },
      {
        id: 3,
        time: "12 Mai",
        title: "Birthday gift",
        author: "Trevor Hansen",
        body: "Have any ideas about what we should get Heidi for her birthday?",
        isRead: true,
      },
      {
        id: 4,
        time: "18 April",
        title: "Recipe to try",
        author: "Trevor Hansen",
        body: "We should eat this: Grate, Squash, Corn, and tomatillo Tacos.",
        isRead: true,
      },
      {
        id: 5,
        time: "12 Mai",
        title: "Birthday gift",
        author: "Trevor Hansen",
        body: "Have any ideas about what we should get Heidi for her birthday?",
        isRead: true,
      },
      {
        id: 6,
        time: "18 April",
        title: "Recipe to try",
        author: "Trevor Hansen",
        body: "We should eat this: Grate, Squash, Corn, and tomatillo Tacos.",
        isRead: true,
      },
      {
        id: 7,
        time: "12 Mai",
        title: "Birthday gift",
        author: "Trevor Hansen",
        body: "Have any ideas about what we should get Heidi for her birthday?",
        isRead: true,
      },
      {
        id: 8,
        time: "18 April",
        title: "Recipe to try",
        author: "Trevor Hansen",
        body: "We should eat this: Grate, Squash, Corn, and tomatillo Tacos.",
        isRead: true,
      },
      {
        id: 9,
        time: "12 Mai",
        title: "Birthday gift",
        author: "Trevor Hansen",
        body: "Have any ideas about what we should get Heidi for her birthday?",
        isRead: true,
      },
      {
        id: 10,
        time: "18 April",
        title: "Recipe to try",
        author: "Trevor Hansen",
        body: "We should eat this: Grate, Squash, Corn, and tomatillo Tacos.",
        isRead: true,
      },
    ],
  };
  /* securedHttpClient.get("/message/outbox", {
      params: {
        customerId: customerid
      }
    });*/
};
export const getMessage = (messageId: any, type: any) => {
  return {
    status: 200,
    data: {
      /* 
        I changed id from 0 to messageId && added the type:type, 
        it wasn't there before.
      */
      id: messageId,
      type: type,
      time: "12 Juli",
      title: "Inbox this weekend?",
      author: "Ali Inboxer",
      body: "I'll be in your neighborhood doing errands this weekend. Do you want to hang out?",
    },
  };
};
/* TODO; Is this API necessary? */
export const readMessage = (id: any) => {
  securedHttpClient.post("/messages/read", {
    params: {
      id: id,
    },
  });
};
/* TODO: Is this API necessary? */
export const sendMessage = (message: any) => {
  securedHttpClient.post("/messages/send", {
    params: {
      message: message,
    },
  });
};
