import { EnvironmentVariables } from "@/environment";
import Vue from "vue";
import Vuex, { ActionTree, GetterTree, MutationTree } from "vuex";
import { AttendanceModule } from "@/store/modules/attendance/attendance.store";
import { CourseModule } from "@/store/modules/course/course.store";
import { CustomerModule } from "@/store/modules/customer/customer.store";
import { ExpensesModule } from "@/store/modules/expenses/expenses.store";
import { FileModule } from "@/store/modules/file/file.store";
import { GradesModule } from "@/store/modules/grades/grades.store";
import { HocModule } from "@/store/modules/hoc/hoc.store";
import { HoursModule } from "@/store/modules/hours/hours.store";
import { MessagesModule } from "@/store/modules/messages/messages.store";
import { NotificationsModule } from "@/store/modules/notifications/notifications.store";
import { RegisterModule } from "@/store/modules/register/register.store";
import { TestModule } from "@/store/modules/test/test.store";
import { TodosModules } from "@/store/modules/todos/todos.store";
import { RootMutations } from "@/store/root-store.mutations";
import { RootStoreState } from "@/store/root-store.state.interface";
import { StoreModules } from "@/store/store-modules.enum";
import { SigninModule } from "@/store/modules/signin/signin.store";
import { GlobalModule } from "@/store/modules/global/global.store";

Vue.use(Vuex);

const initialState: () => RootStoreState = () => ({
  isLoading: false,
});

const state = initialState();

const mutations = <MutationTree<RootStoreState>>{
  [RootMutations.SET_LOADING](state, isLoading: boolean) {
    state.isLoading = isLoading;
  },
};

const getters = <GetterTree<RootStoreState, RootStoreState>>{};

const actions = <ActionTree<RootStoreState, RootStoreState>>{};

export default new Vuex.Store<RootStoreState>({
  strict: EnvironmentVariables.DEVELOPMENT,
  state: {
    ...state,
  },
  mutations,
  actions,
  getters,
  modules: {
    [StoreModules.Global]: GlobalModule,
    [StoreModules.Todo]: TodosModules,
    [StoreModules.Attendance]: AttendanceModule,
    [StoreModules.Course]: CourseModule,
    [StoreModules.Customer]: CustomerModule,
    [StoreModules.Expenses]: ExpensesModule,
    [StoreModules.File]: FileModule,
    [StoreModules.Grades]: GradesModule,
    [StoreModules.Hoc]: HocModule,
    [StoreModules.Hours]: HoursModule,
    [StoreModules.Messages]: MessagesModule,
    [StoreModules.Notifications]: NotificationsModule,
    [StoreModules.Register]: RegisterModule,
    [StoreModules.Signin]: SigninModule,
    [StoreModules.Test]: TestModule,
  },
});
