import { getMessages, getMessage, readMessage, sendMessage } from "@/api/message.api";
import { RootStoreState } from "@/store/root-store.state.interface";
import { ActionTree, MutationTree } from "vuex";
import { MessagesStoreState } from "@/store/modules/messages/messages.store.interface";
import { MessagesActions } from "@/store/modules/messages/messages.actions.enum";
import { MessagesMutations } from "@/store/modules/messages/messages.mutations.enum";
import { api } from "@/api/api";

const initialState: () => MessagesStoreState = () => ({
  inboxMessages: [],
  outboxMessages: [],
  message: {},
  recipients: [],
  unreadMessages: 0,
});

const state = initialState();

const getters = {
  getInboxMessages(state: any) {
    return state.inboxMessages;
  },
  getOutboxMessages(state: any) {
    return state.outboxMessages;
  },
  getMessage(state: any) {
    return state.message;
  },
  getRecipents(state: any) {
    return state.recipients;
  },
};

const actions = <ActionTree<MessagesStoreState, RootStoreState>>{
  [MessagesActions.Reset]({ commit }) {
    commit(MessagesMutations.RESET);
  },
  async [MessagesActions.FetchMessages]({ commit, dispatch }, { customerId, type }) {
    try {
      dispatch("hoc/setLoading", null, { root: true });
      const response = await getMessages(customerId, type);
      dispatch("hoc/removeLoading", null, { root: true });
      commit(MessagesMutations.SET_MESSAGES, {
        response: response.data,
        type: type,
      });
    } catch (error) {
      console.error(error);
    }
  },
  async [MessagesActions.FetchMessage]({ commit, dispatch, state }, { messageId, type }) {
    try {
      if (type == "inbox" && state.inboxMessages.length > 0) {
        const message = state.inboxMessages.filter((message: any) => {
          return message["id"] === messageId;
        });
        commit(MessagesMutations.SET_MESSAGE, message[0]);
      } else if (type == "outbox" && state.outboxMessages.length > 0) {
        const message = state.inboxMessages.filter((message: any) => {
          return message["id"] === messageId;
        });
        commit(MessagesMutations.SET_MESSAGE, message[0]);
      } else {
        dispatch("hoc/setLoading", null, { root: true });
        const response = await getMessage(messageId, type);
        dispatch("hoc/removeLoading", null, { root: true });
        commit(MessagesMutations.SET_MESSAGE, response.data);
      }
    } catch (error) {
      console.error(error);
    }
  },
  async [MessagesActions.ReadMessage]({ commit }, id) {
    try {
      await readMessage(id);
      commit(MessagesMutations.SET_MESSAGES_READ, id);
    } catch (error) {
      console.error(error);
    }
  },
  async [MessagesActions.SendMessage]({ dispatch }, message) {
    try {
      dispatch("hoc/setLoading", null, { root: true });
      await sendMessage(message);
      dispatch("hoc/removeLoading", null, { root: true });
    } catch (error) {
      console.error(error);
    }
  },
  async [MessagesActions.FetchUnreadMessages]({ commit }) {
    commit(MessagesMutations.SET_UNREAD_MESSAGES, (await api.messaging.getNumberOfUnreadMessagesMinSide()).data);
  },
};

const mutations = <MutationTree<MessagesStoreState>>{
  //  Will always add a reset mutation so we can use the global reset.
  [MessagesMutations.RESET](state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key as keyof MessagesStoreState] = newState[key as keyof MessagesStoreState];
    });
  },
  [MessagesMutations.SET_MESSAGES](state, data) {
    if (data.type == "inbox") {
      state.inboxMessages = data.response;
    } else {
      state.outboxMessages = data.response;
    }
  },
  [MessagesMutations.SET_MESSAGES_READ](state, data) {
    const index = state.inboxMessages.findIndex((message) => message.id === data);
    const updatedMessage = state.inboxMessages[index];
    updatedMessage.isRead = true;
    state.inboxMessages.splice(index, 1, updatedMessage);
  },
  [MessagesMutations.SET_MESSAGE](state, data) {
    state.message = data;
  },
  [MessagesMutations.SET_RECIPENTS](state, data) {
    const simplifiedRecipents = data.map((item: any) => {
      const recipents = {
        id: item.customer.customerId,
        name: item.customer.firstName + " " + item.customer.lastName,
      };
      return recipents;
    });
    state.recipients = simplifiedRecipents;
  },
  [MessagesMutations.SET_UNREAD_MESSAGES](state, data) {
    state.unreadMessages = data;
  },
};

export const MessagesModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
