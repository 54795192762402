// TODO update!
export const EnvironmentVariables = Object.freeze({
  VUE_APP_SEC_API_URL: process.env.VUE_APP_SEC_API_URL,
  VUE_APP_API_OCP_KEY: process.env.VUE_APP_API_OCP_KEY,
  PRODUCTION: process.env.NODE_ENV === "production",
  DEVELOPMENT: process.env.NODE_ENV === "development",
  BASE_URL: process.env.BASE_URL,
  SUBSCRIPTION_KEY: process.env.SUBSCRIPTION_KEY,

  IN_DEV_ENV: process.env.VUE_APP_ENV_NAME === "Development",
  IN_PRODUCTION_ENV: process.env.VUE_APP_ENV_NAME === "Production",
  IN_DEV_OR_TEST_ENV: process.env.VUE_APP_ENV_NAME === "Development" || process.env.VUE_APP_ENV_NAME === "Test", // use for feature flagging
  NOT_IN_PROD_ENV: process.env.VUE_APP_ENV_NAME !== "Production", // use for feature flagging
  NOT_IN_PROD_OR_STAGING: process.env.VUE_APP_ENV_NAME !== "Production" && process.env.VUE_APP_ENV_NAME !== "Staging", // use for feature flagging

  APP_REV: process.env.APP_REV?.slice(0, 7),
  HTTP_TIMEOUT: 30000,
  GTM_ID: "GTM-WR3F5PH",
});
