var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "display": "contents"
    }
  }, [_c('v-list-item-title', {
    class: _vm.isRead
  }, [_vm._v(" " + _vm._s(_vm.item.title) + " ")]), _c('v-list-item-subtitle', {
    class: _vm.isRead
  }, [_vm._v(" " + _vm._s(_vm.item.subtTitle) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }