
import { defineComponent } from "@vue/composition-api";
import { mapGetters, mapActions } from "vuex";
import TheEmptyNotification from "@/components/notifications/TheEmptyNotification.vue";
import TheNotificationsList from "@/components/notifications/TheNotificationsList.vue";

export default defineComponent({
  name: "TheNotifications",
  components: {
    TheEmptyNotification,
    TheNotificationsList,
  },
  computed: {
    ...mapGetters("notifications", ["getNotifications"]),
    emptyNotifications() {
      if ((this as any).getNotifications.length === 0) {
        return true;
      }
      return false;
    },
  },
  async mounted() {
    if ((!this as any).getNotifications?.length) {
      await (this as any).fetchNotifications({
        customerId: JSON.parse((localStorage as any).getItem("customer")),
      });
    }
  },
  methods: {
    ...mapActions("notifications", ["readNotification", "fetchNotifications"]),
    notificationLink(route: any, id: any) {
      (this as any).readNotification(id);
      this.$router.push({ name: route });
    },
  },
});
