import { ApiGetCustomerDto } from "@/api/generated/Api";

export function getFullName(customer: ApiGetCustomerDto | undefined) {
  if (customer?.customer !== undefined) {
    const firstName = customer.customer.firstName;
    const lastName = customer.customer.lastName;
    return firstName + " " + lastName;
  }
  return "";
}

export function getShortName(customer: ApiGetCustomerDto | undefined) {
  if (customer?.customer !== undefined) {
    const firstCharacter = customer.customer.firstName.charAt(0).toUpperCase();
    const lastCharacter = customer.customer.lastName.charAt(0).toUpperCase();
    return firstCharacter + lastCharacter;
  }
  return "";
}
