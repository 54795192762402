
import { defineComponent } from "@vue/composition-api";
import { mapState } from "vuex";

export default defineComponent({
  name: "TheModalContainer",
  computed: {
    ...mapState("hoc", {
      modal: (state: any) => state.modal,
    }),
    /* TODO: Once we have a solution for Modals this function needs to be changed */
    component() {
      return this.isOpen ? () => import(`@/components/shared/modal/${this.modal}.vue`) : null;
    },
    isOpen() {
      return !!this.modal;
    },
  },
});
