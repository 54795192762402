import { securedHttpClient, kursAdminHttpClient } from "@/api/httpClient";
const customer = {
  stringCharacters: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
  integerCharacters: "0123456789",
  generatePhoneNumber() {
    return Math.floor(Math.random() * (99999999 - 10000000 + 1)) + 10000000;
  },
  generateCharacters(input: any, length: any) {
    let name = "";
    for (let i = 0; i < length; i++) {
      name += input.charAt(Math.floor(Math.random() * input.length));
    }
    return name;
  },
  generateString(length: any) {
    return customer.generateCharacters(customer.stringCharacters, length);
  },
  generateNumber(length: any) {
    return customer.generateCharacters(customer.integerCharacters, length);
  },
  generateDouble(length: any) {
    return parseFloat(customer.generateNumber(length) + "." + customer.generateNumber(2));
  },
  randomBoolean() {
    return Math.floor(Math.random() * 100) % 2 === 0 ? true : false;
  },
};

const course = {
  startHour: 8,
  endHour: 12,
  generateDate(dayChange?: any, hour?: any) {
    const validatedDayChange = dayChange ? dayChange : 0;
    const validatedHour = hour ? hour : 0;
    const [day, month, year] = new Date().toLocaleDateString().split("/");
    const dayInt = parseInt(day, 10);
    const monthInt = parseInt(month, 10);
    const yearInt = parseInt(year, 10);
    const date = new Date(yearInt, monthInt - 1, dayInt + validatedDayChange, validatedHour, 0, 0).toISOString();
    return date;
  },
  generateSchedule(startDayChange: any, endDayChange: any) {
    const validatedStartDayChange = startDayChange ? startDayChange : 0;
    const validatedEndDayChange = endDayChange ? endDayChange : 1;
    const daysNumber = validatedEndDayChange - validatedStartDayChange;
    const scheduleList = [];
    for (let i = 0; i <= daysNumber; i++) {
      const scheduleDate = {
        title: "Forelesning",
        start: this.generateDate(validatedStartDayChange + i, this.startHour),
        end: this.generateDate(validatedStartDayChange + i, this.endHour),
        inserted: this.generateDate(),
        insertedBy: 1,
        updated: this.generateDate(),
        updatedBy: 1,
      };
      scheduleList.push(scheduleDate);
    }
    return scheduleList;
  },
};
export const postTeachingMaterial = () => {
  return securedHttpClient.post(`/teachingmaterial`, {
    name: "TestMaterial",
    description: "TestmMterialer for testing av AOF MinSide",
    isActive: true,
    attribute1: "TST",
    category: "Bok",
  });
};
export const postCurriculum = (teachingMaterialId: any) => {
  return kursAdminHttpClient.post(`/curriculum`, {
    name: "Testpakken",
    orgId: 1,
    mainCourseId: 1113,
    subCourseId: 1160,
    cefrLevel: "T1",
    hoursMin: 10,
    hoursMax: 1000,
    hoursOnline: 10,
    hoursStudyLoad: 10,
    levelId: 34,
    points: 10,
    evaluationId: 38,
    teacherRequired: true,
    teacherRequirements: "",
    foreknowledgeRequired: true,
    foreknowledgeRequirements: "Kunnskap testing",
    targetGroup: "Bruker av MinSide",
    learningObjectives: "Test av MinSide",
    course: {
      content: "Test content",
      method: "Test metode",
      teachingMaterials: [teachingMaterialId],
    },
    courseCertificateRequired: true,
    webContent: "No content",
    teachingMethod: "klasserom",
  });
};
export const updateCurriculum = (curriculumId: any) => {
  return kursAdminHttpClient.put(`/curriculum/${curriculumId}`, {
    name: "Testpakken",
    statusId: 30,
    mainCourseId: 1113,
    subCourseId: 1160,
    teachingMethod: "klasserom",
    hoursMin: 10,
    hoursMax: 1000,
    orgId: 1,
    levelId: 34,
    evaluationId: 38,
    cefrLevel: "T1",
    learningObjectives: "Test av MinSide",
    course: {
      content: "Test content",
      method: "Test metode",
    },
  });
};
export const postExecutionPlan = (curriculumId: any) => {
  return kursAdminHttpClient.post(`/curriculum/${curriculumId}/executionplan`, {
    info: {
      reasonForStatusChangeRequired: false,
      cancellationDeadlineRequired: true,
      hoursWithoutInstructorRequired: true,
      hoursSelfStudyRequired: true,
      enrollmentDeadlineRequired: true,
      unenrollmentDeadlineRequired: true,
      minimumParticipantsRequired: true,
      maximumParticipantsRequired: true,
      availableParticipantsRequired: true,
      financiersRequired: true,
      priceRequired: true,
      additionalFilesRequired: true,
      groupIdRequired: true,
    },
    plan: true,
    economyRequired: true,
    certificatesRequired: true,
    certificate: {
      courseCertificateRequired: true,
      gradeCertificateRequired: true,
      diplomaRequired: true,
    },
    teachingMaterialsRequired: true,
    testsRequired: true,
    testDescriptionRequired: true,
  });
};

export const postArea = () => {
  return kursAdminHttpClient.post(`/area`, {
    country: "Norway",
    countryCode: "NO",
    county: "Testfylket",
    municipality: "Testkommune",
    place: "Teststed",
    studyLocationCode: "TSTSTD",
  });
};

export const postLocation = (areaId: any) => {
  return kursAdminHttpClient.post(`/area/${areaId}/location`, {
    street: "TestGate",
    postalCode: "0160",
    postalArea: "Testomrade",
    isActive: true,
    details: "Dette er test lokasjon",
  });
};

export const postRoom = (areaId: any, locationId: any) => {
  return kursAdminHttpClient.post(`/area/${areaId}/location/${locationId}/room`, {
    name: "Testrom",
    capacity: 100,
    recommendedCapacity: 100,
    floorNumber: 1,
    isActive: true,
    description: "Dette er test rom",
  });
};

export const postTestType = () => {
  return kursAdminHttpClient.post(`/testtype`, {
    name: "TestType for MinSide",
    description: "Dette er test type som er brukt bare for testing av MinSide",
  });
};

export const postCourse = (
  name: any,
  startDate: any,
  endDate: any,
  status: any,
  curriculumId: any,
  areaId: any,
  locationId: any,
  roomId: any,
  contactId: any,
  lecturerId: any,
  supervisorId: any,
  teachingMaterialId: any,
  testTypeId: any
) => {
  return kursAdminHttpClient.post(`/course`, {
    curriculumId: curriculumId,
    status: status,
    courseName: name,
    reasonForStatusChange: "Oppretelse av kurs",
    useLearningPlatform: customer.randomBoolean(),
    courseLocation: {
      areaId: areaId,
      locationId: locationId,
      roomId: roomId,
    },
    cancellationDeadline: course.generateDate(startDate - 8),
    startDate: course.generateDate(startDate),
    endDate: course.generateDate(endDate),
    startsAt: {
      hour: course.startHour,
      minute: 0,
      second: 0,
    },
    endsAt: {
      hour: course.endHour,
      minute: 0,
      second: 0,
    },
    hoursWithInstructor: customer.generateNumber(3),
    hoursWithoutInstructor: customer.generateNumber(3),
    hoursOnline: customer.generateNumber(2),
    hoursSelfStudy: customer.generateNumber(2),
    courseSupervisorId: supervisorId,
    organizerOrganizationId: 1,
    ownerOrganizationId: 1,
    lecturerId: lecturerId,
    contactId: contactId,
    enrollmentDeadline: course.generateDate(startDate - 15),
    minimumParticipants: 1,
    maxParticipants: 20,
    participantCapacity: 0,
    financedByOrganizationId: 1,
    fileIds: [],
    isOrderable: true,
    approvedByLanekassen: customer.randomBoolean(),
    plan: {
      registerHours: true,
      schedules: course.generateSchedule(startDate, endDate),
    },
    additionalTeachingMaterialIds: [teachingMaterialId],
    tests: [
      {
        testTypeId: testTypeId,
        name: "test",
        description: "ingen tekst",
      },
    ],
    comments: "Det er test",
    economy: {
      cancellationFee: customer.generateDouble(4),
      price: customer.generateDouble(5),
      trainingGrantCode: customer.generateNumber(4),
      grantCode: customer.generateNumber(4),
      allowsDiscount: customer.randomBoolean(),
      allowExpenses: customer.randomBoolean(),
    },
  });
};

export const updateCourse = (
  courseId: any,
  curriculumId: any,
  courseName: any,
  status: any,
  statusReason: any,
  startDate: any,
  endDate: any,
  startHour: any,
  endHour: any,
  hoursWithInstructor: any,
  hoursWithoutInstructor: any,
  organizerOrganizationId: any,
  ownerOrganizationId: any,
  supervisorId: any,
  lecturerId: any,
  contactId: any,
  areaId: any,
  locationId: any,
  roomId: any,
  cancellationDeadline: any,
  enrollmentDeadline: any,
  financedByOrganizationId: any,
  hoursSelfStudy: any,
  minimumParticipants: any,
  maxParticipants: any,
  plan: any,
  additionalTeachingMaterialIds: any,
  tests: any
) => {
  return kursAdminHttpClient.put(`/course/${courseId}`, {
    curriculumId: curriculumId,
    status: status,
    courseName: courseName,
    reasonForStatusChange: statusReason,
    courseLocation: {
      areaId: areaId,
      locationId: locationId,
      roomId: roomId,
    },
    cancellationDeadline: cancellationDeadline,
    startDate: startDate,
    endDate: endDate,
    startsAt: {
      hour: startHour,
      minute: 0,
      second: 0,
    },
    endsAt: {
      hour: endHour,
      minute: 0,
      second: 0,
    },
    hoursWithInstructor: hoursWithInstructor,
    hoursWithoutInstructor: hoursWithoutInstructor,
    hoursSelfStudy: hoursSelfStudy,
    courseSupervisorId: supervisorId,
    organizerOrganizationId: organizerOrganizationId,
    ownerOrganizationId: ownerOrganizationId,
    lecturerId: lecturerId,
    contactId: contactId,
    enrollmentDeadline: enrollmentDeadline,
    minimumParticipants: minimumParticipants,
    maxParticipants: maxParticipants,
    financedByOrganizationId: financedByOrganizationId,
    plan: plan,
    additionalTeachingMaterialIds: additionalTeachingMaterialIds,
    tests: tests,
    comments: "Dette er oppdatert testkurs",
  });
};

export const postCustomer = () => {
  const address = {
    post: customer.generateString(7),
    city: customer.generateString(6),
    zip: customer.generateNumber(4),
    country: "NO",
  };
  const comms = {
    phone: customer.generatePhoneNumber(),
    mobile: customer.generatePhoneNumber(),
    email: `${customer.generateString(5)}@${customer.generateString(4)}.net`,
  };
  const person = {
    firstName: `test ${customer.generateString(5)}`,
    lastName: customer.generateString(7),
    birthDate: "1990-01-01T00:00:00",
    ssno: customer.generateNumber(11),
    gender: "M",
  };
  return securedHttpClient.post(`login/customer/person`, {
    organizationId: 1,
    postAddress1: address.post,
    postAddress2: "",
    postAddress3: "",
    postCity: address.city,
    postZip: address.zip,
    postCountry: address.country,
    invoiceAddress1: address.post,
    invoiceAddress2: "",
    invoiceAddress3: "",
    invoiceCity: address.city,
    invoiceZip: address.zip,
    invoiceCountry: address.country,
    phoneNumber: comms.phone,
    mobileNumber: comms.mobile,
    email: comms.email,
    isActive: true,
    customer: {
      title: "",
      firstName: person.firstName,
      middleName: "",
      lastName: person.lastName,
      birthDate: person.birthDate,
      ssno: person.ssno,
      gender: person.gender,
      allowDm: true,
      adminAccess: false,
    },
  });
};

export const postParticipant = (courseId: any, customerId: any, role: any, status: any) => {
  return kursAdminHttpClient.post(`/course/${courseId}/participant`, {
    status: status,
    userId: customerId,
    roleName: role,
  });
};
