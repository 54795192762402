var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.notificationList ? _c('div', _vm._l(_vm.notificationList, function (notification) {
    return _c('section', {
      key: notification.guid
    }, [_c('v-alert', {
      attrs: {
        "dismissible": "",
        "type": notification.type
      },
      on: {
        "input": function ($event) {
          return _vm.removeNotification(notification);
        }
      }
    }, [_vm._v(" " + _vm._s(notification.message) + " ")])], 1);
  }), 0) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }