import { getCourses } from "@/api/course.api";
import { FileActions } from "@/store/modules/file/file.actions.enum";
import { FileMutations } from "@/store/modules/file/file.mutations.enum";

import {
  deleteFile,
  downloadFile,
  getFile,
  getFileCategories,
  getFileEntities,
  getFileList,
  uploadFile,
} from "@/api/file.api";
import { beginDownloading, checkMimeType } from "@/shared/helpers/otherHelpers";
import { FileStoreState } from "@/store/modules/file/file.store.interface";
import { RootStoreState } from "@/store/root-store.state.interface";
import { ActionTree, MutationTree } from "vuex";

const initialState: () => FileStoreState = () => ({
  categories: [],
  entities: [],
  courses: [],
  files: [],
  file: {},
  filePreview: "",
  temporaryFileStorage: {},
});

const state = initialState();

const getters = {
  getFileCategories(state: any) {
    return state.categories;
  },
  getFileEntities(state: any) {
    return state.entities;
  },
  getFileCourses(state: any) {
    return state.courses;
  },
  getFiles(state: any) {
    return state.files;
  },
  getFile(state: any) {
    return state.file;
  },
  getFilePreview(state: any) {
    return state.filePreview;
  },
  getTemporaryFile(state: any) {
    return state.temporaryFileStorage;
  },
};

const actions = <ActionTree<FileStoreState, RootStoreState>>{
  [FileActions.Reset]({ commit }) {
    commit(FileMutations.RESET);
  },
  async [FileActions.FetchFileCategories]({ commit }) {
    try {
      const response = await getFileCategories();
      if (response.status === 200) {
        const categories = response.data.map((item) => {
          const category = {
            label: "",
            value: item,
          };
          switch (item) {
            case "diplom":
              category.label = "table.documents.categories.diploma";
              break;
            case "søknadsbrev":
              category.label = "table.documents.categories.resume";
              break;
            case "cv":
              category.label = "table.documents.categories.cv";
              break;
            default:
              category.label = "table.documents.categories.other";
              break;
          }
          return category;
        });
        commit(FileMutations.SET_CATEGORIES, categories);
      }
    } catch (error) {
      console.error(error);
    }
  },
  async [FileActions.FetchFileEntities]({ commit }) {
    try {
      const response = await getFileEntities();
      if (response.status === 200) {
        const entities = response.data.map((item) => {
          const entity = {
            label: "",
            value: item,
          };
          switch (item) {
            case "course":
              entity.label = "table.documents.entities.course";
              break;
            default:
              entity.label = "table.documents.entities.user";
              break;
          }
          return entity;
        });
        commit(FileMutations.SET_ENTITIES, entities);
      }
    } catch (error) {
      console.error(error);
    }
  },
  async [FileActions.FetchCoursesFiles]({ commit }, payload) {
    try {
      let filesList = [];
      const courses = await getCourses();
      const coursesList = courses.data.map((item: any) => {
        const allowedCourses = ["åpnet", "utsatt"];
        const checkAllowedCourseStatus = () => {
          if (
            allowedCourses.findIndex((course) => {
              return item.course.status === course;
            }) > -1
          ) {
            return true;
          }
          return false;
        };
        if (checkAllowedCourseStatus()) {
          return {
            label: item.course.courseName,
            value: item.courseId,
          };
        }
      });
      const filteredCourseList = coursesList.filter((course: any) => course !== undefined);
      commit(FileMutations.SET_COURSES, filteredCourseList);
      for (const course of filteredCourseList) {
        const response = await getFileList(payload.entity, course.value);
        filesList = response.data.map((file: any) => {
          file.courseId = course.value;
          file.courseName = course.label;
          return file;
        });
      }
      commit(FileMutations.SET_FILES, filesList);
    } catch (error) {
      console.error(error);
    }
  },
  async [FileActions.FetchFiles]({ commit }, payload) {
    try {
      const response = await getFileList(payload.entity, payload.id);
      commit(FileMutations.SET_FILES, response.data);
    } catch (error) {
      console.error(error);
    }
  },
  async [FileActions.ViewFile]({ dispatch, commit }, payload) {
    try {
      dispatch("hoc/setLoading", null, { root: true });
      const response = await getFile(payload.fileId);
      if (response.status === 200) {
        const url = URL.createObjectURL(response.data);
        commit(FileMutations.SET_FILE_PREVIEW, url);
      }
      dispatch("hoc/removeLoading", null, { root: true });
    } catch (error) {
      dispatch("hoc/removeLoading", null, { root: true });
      console.error(error);
    }
  },
  async [FileActions.UploadFile]({ dispatch }, payload) {
    try {
      dispatch("hoc/setLoading", null, { root: true });
      const formData = new FormData();
      formData.append("entities", JSON.stringify({ [payload.entity]: payload.id }));
      formData.append("file", payload.file);
      formData.append("description", payload.description);
      formData.append("category", payload.category);
      const response = await uploadFile(payload.entity, payload.id, formData);
      dispatch("hoc/removeLoading", null, { root: true });
      /* Need to find a solution to the response object as it is not a string */
      /*  openNotification(this as any, NotificationItemType.Success, response); */
      dispatch("hoc/setSnackbar", response, { root: true });
      if (response.status === 200) {
        dispatch(
          "fetchFiles",
          {
            id: payload.id,
            entity: payload.entity,
          },
          { root: true }
        );
      }
    } catch (error) {
      console.error(error);
    }
  },
  async [FileActions.DownloadFile]({ commit }, payload) {
    try {
      let fileName = payload.fileName || "";

      if (!payload.fileName) {
        const extension = checkMimeType(payload.documentFileType);
        const filteredCourseName = payload.courseName ? payload.courseName.replace(/\s+/g, "-") : undefined;
        const courseName = payload.entity === "user" ? "Personlig" : filteredCourseName;
        fileName = `AOF-${courseName}.${extension}`;
      }

      const response = await downloadFile(payload.documentId);

      commit(FileMutations.DOWNLOAD_FILE, {
        content: response.data,
        fileName: fileName,
      });
    } catch (error) {
      console.error(error);
    }
  },
  async [FileActions.DeleteFile]({ dispatch }, documentId) {
    try {
      dispatch("hoc/setLoading", null, { root: true });
      await deleteFile(documentId);
      dispatch("hoc/removeLoading", null, { root: true });
    } catch (error) {
      console.error(error);
    }
  },
  [FileActions.SetTemporaryFile]({ commit }, fileData) {
    commit(FileMutations.SET_TEMPORARY_FILE, fileData);
  },
};

const mutations = <MutationTree<FileStoreState>>{
  //  Will always add a reset mutation so we can use the gloabal reset.
  [FileMutations.RESET](state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key as keyof FileStoreState] = newState[key as keyof FileStoreState];
    });
  },
  [FileMutations.SET_CATEGORIES](state, data) {
    state.categories = data;
  },
  [FileMutations.SET_ENTITIES](state, data) {
    state.entities = data;
  },
  [FileMutations.SET_COURSES](state, data) {
    state.courses = data;
  },
  [FileMutations.SET_FILES](state, data) {
    const filteredFiles = data.map((item: any) => {
      const filteredItem = { ...item };
      const date = item.inserted;
      const splitDate = date.split("T");
      const filterDate = splitDate[0].replaceAll("-", "/");
      const filterHour = splitDate[1].split(".");
      filteredItem.inserted = filterDate + " | " + filterHour[0];

      let filteredFileType = "";
      switch (item.mimeType) {
        case "image/jpeg":
          filteredFileType = "table.documents.mimeTypes.image";
          break;
        case "image/jpg":
          filteredFileType = "table.documents.mimeTypes.image";
          break;
        case "image/png":
          filteredFileType = "table.documents.mimeTypes.image";
          break;
        case "application/pdf":
          filteredFileType = "table.documents.mimeTypes.pdf";
          break;
        default:
          filteredFileType = "table.documents.mimeTypes.none";
          break;
      }
      filteredItem.mimeType = filteredFileType;
      return filteredItem;
    });
    state.files = filteredFiles;
  },
  [FileMutations.SET_FILE](state, data) {
    state.file = data;
  },
  [FileMutations.SET_FILE_PREVIEW](state, data) {
    state.filePreview = data;
  },
  [FileMutations.DOWNLOAD_FILE](state, data) {
    state.file = data;
    beginDownloading(data.content, data.fileName);
  },
  [FileMutations.SET_TEMPORARY_FILE](state, data) {
    state.temporaryFileStorage = data;
  },
};

export const FileModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
