var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.isRouterReady ? _c('v-container', {
    staticClass: "d-flex align-center",
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center"
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "color": "primary"
    }
  })], 1)], 1)], 1) : _vm.requiresAuth && !_vm.isAuthenticated ? _c('v-container', {
    staticClass: "d-flex align-center",
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "color": "primary"
    }
  }), _c('div', {
    staticClass: "mt-4"
  }, [_vm._v("Logger inn på Min Side...")])], 1)], 1)], 1) : _c('v-main', [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }