import { StoreModules } from "@/store/store-modules.enum";
/* import { StoreMutationsType } from "@/store/store.mutations"; */
import { StoreState } from "@/store/store.state.interface";
import { Store } from "vuex";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { NotificationItem } from "@/shared/interfaces/notificationItem.interface";
import { StoreActions } from "@/store/store.actions";

/** useStoreAction(store, StoreModules.Hoc, "successSnackbar", "Some text"); */
export function runStoreAction(
  store: Store<StoreState>,
  module: StoreModules,
  element: string,
  payload?: any
): Promise<any> {
  return store.dispatch(`${getModulePath(module)}${element}`, payload);
}

/** useStoreMutation(store, StoreModules.Hoc, StoreMutations.HocMutations.OPEN_SNACKBAR, "Some text"); */
export function runStoreMutation(store: Store<StoreState>, module: StoreModules, mutation: string, value?: any): void {
  store.commit(`${getModulePath(module)}${mutation}`, value);
}

export function openNotification(
  store: Store<StoreState>,
  type: NotificationItemType,
  message: string,
  timeOut?: number
) {
  const notification: NotificationItem = {
    message,
    type,
    timeOut,
  };
  runStoreAction(store, StoreModules.Hoc, StoreActions.HocActions.OpenNotification, notification);
}

function getModulePath(module: string | null) {
  if (!module || module.length === 0) {
    return "";
  }
  return module + "/";
}
