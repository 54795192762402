
import { defineComponent } from "@vue/composition-api";
import { useRouter } from "@/shared/useHelpers";

export default defineComponent({
  name: "TheHelpButton",
  setup() {
    const router = useRouter();
    function navigateToHelpPage() {
      router.push("/helpPage");
    }
    return {
      navigateToHelpPage,
    };
  },
});
