export const getCourseHours = (courseId: any) => {
  const hoursList: any[] = [];
  return {
    status: 200,
    data: hoursList,
    courseId,
  };
};

export const UpdateCourseHour = (payload: any) => {
  return {
    status: 200,
    data: payload,
  };
};
