
import { defineComponent } from "@vue/composition-api";
import TheNotifications from "@/components/shell/app-router/TheNotifications.vue";
import TheProfileInfo from "./TheProfileInfo.vue";
import TheHelpButton from "./TheHelpButton.vue";

export default defineComponent({
  name: "TheNavIcons",
  components: { TheNotifications, TheProfileInfo, TheHelpButton },
});
