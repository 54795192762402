import { getField, updateField } from "vuex-map-fields";
import { createcustomer } from "@/api/login.api";
import router from "@/router/router";
import { static_register } from "@/api/login.api";
import { RegisterStoreState } from "@/store/modules/register/register.store.interface";
import { RootStoreState } from "@/store/root-store.state.interface";
import { ActionTree, MutationTree } from "vuex";
import { RegisterActions } from "@/store/modules/register/register.actions.enum";
import { RegisterMutations } from "@/store/modules/register/register.mutations.enum";

const initialState: () => RegisterStoreState = () => ({
  signIn: {},
});

const state = initialState();

const getters = {
  getField,
};

const actions = <ActionTree<RegisterStoreState, RootStoreState>>{
  [RegisterActions.Reset]({ commit }) {
    commit(RegisterMutations.RESET);
  },
  [RegisterActions.SetUserObject]({ commit }, user) {
    commit(RegisterMutations.SET_SIGNIN_FB, user);
    localStorage.setItem("user", JSON.stringify(user));
  },
  async [RegisterActions.StaticRegister]({ dispatch }) {
    try {
      dispatch("hoc/setLoading", null, { root: true });
      const newCustomer = await createcustomer(state.signIn);
      const registerRequest = {
        email: state.signIn.email.trim().toLowerCase(),
        password: btoa(state.signIn.password),
        id: newCustomer.data.id,
      };
      const setPassword = await static_register(registerRequest);
      dispatch("hoc/removeLoading", null, { root: true });
      if (setPassword.status === 204) {
        router.push("/login");
      }
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = <MutationTree<RegisterStoreState>>{
  updateField,
  [RegisterMutations.RESET](state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key as keyof RegisterStoreState] = newState[key as keyof RegisterStoreState];
    });
  },
  [RegisterMutations.SET_BIRTHDATE](state, value) {
    state.signIn.birthDate = value;
  },
  [RegisterMutations.SET_SIGNIN_FB](state, user) {
    state.signIn = {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
    };
  },
};

export const RegisterModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
