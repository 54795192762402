import { Route } from "vue-router";

export const MessagesRouteList = [
  {
    path: "/messages/:tab?",
    name: "messages",
    component: () => import(/* webpackChunkName: "Messages" */ "@/pages/minside/messages/Messages.vue"),
    props: (route: Route) => ({
      routeTab: route.params.tab,
    }),
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { text: "Dashbord", to: "/" },
        {
          text: "Meldinger",
          disabled: true,
          to: "/messages",
        },
      ],
    },
  },
  {
    path: "/message/:messageId",
    name: "message",
    component: () => import(/* webpackChunkName: "Message" */ "@/pages/minside/messages/Message.vue"),
    props: (route: Route) => ({
      messageId: parseInt(route.params.messageId, 10),
    }),
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { text: "Dashbord", to: "/" },
        {
          text: "Meldinger",
          to: "/messages",
          exact: true,
        },
        {
          text: "Vis melding",
          disabled: true,
          to: "/message",
        },
      ],
    },
  },
];
