import { securedHttpClient } from "./httpClient";
import { Api } from "./generated/Api";

const api = new Api();
// Override autogenerated Axios instance with custom instance for API
api.instance = securedHttpClient;

const binaryApi = new Api({ format: "blob" });
// Override autogenerated Axios instance with custom instance for API
binaryApi.instance = securedHttpClient;

export { api, binaryApi };
