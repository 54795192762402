import { securedHttpClient } from "@/api/httpClient";

export const getFileCategories = () => {
  return {
    status: 200,
    data: ["diplom", "søknadsbrev", "cv", "annet"],
  };
};

export const getFileEntities = () => {
  return {
    status: 200,
    data: ["course", "user"],
  };
};

export const getFileList = (entity: any, id: any) => securedHttpClient.get(`/file/${entity}/${id}`);

export const getFile = (id: any) =>
  securedHttpClient.get(`/file/${id}`, {
    responseType: "blob",
  });

export const uploadFile = (entity: any, id: any, formData: any) =>
  securedHttpClient.post(`/file/${entity}/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const getWebapplicationFilesByUserId = (courseId: any, userId: any) =>
  securedHttpClient.get(`/file/course/${courseId}/participant/${userId}`);

export const downloadFile = (id: any) =>
  securedHttpClient.get(`/file/${id}`, {
    responseType: "blob",
  });

export const deleteFile = (id: any) => securedHttpClient.delete(`/file/${id}`);
