import { getNotifications, readNotification } from "@/api/notification.api";
import { RootStoreState } from "@/store/root-store.state.interface";
import { ActionTree, MutationTree } from "vuex";
import { NotificationsStoreState } from "@/store/modules/notifications/notifications.store.interface";
import { NotificationsActions } from "@/store/modules/notifications/notifications.actions.enum";
import { NotificationsMutations } from "@/store/modules/notifications/notifications.mutations.enum";

const initialState: () => NotificationsStoreState = () => ({
  notifications: [],
});

const state = initialState();

const getters = {
  getNotifications(state: any) {
    return state.notifications;
  },
};

const actions = <ActionTree<NotificationsStoreState, RootStoreState>>{
  [NotificationsActions.Reset]({ commit }) {
    commit(NotificationsMutations.RESET);
  },
  async [NotificationsActions.FetchNotifications]({ commit, dispatch }, { customerId }) {
    try {
      dispatch("hoc/setLoading", null, { root: true });
      const response = await getNotifications(customerId);
      dispatch("hoc/removeLoading", null, { root: true });
      commit(NotificationsMutations.SET_NOTIFICATIONS, {
        response: response.data,
      });
    } catch (error) {
      console.error(error);
    }
  },
  async [NotificationsActions.ReadNotification]({ commit }, id) {
    try {
      await readNotification(id);
      commit(NotificationsMutations.SET_NOTIFICATION_READ, id);
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = <MutationTree<NotificationsStoreState>>{
  [NotificationsMutations.RESET](state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key as keyof NotificationsStoreState] = newState[key as keyof NotificationsStoreState];
    });
  },
  [NotificationsMutations.SET_NOTIFICATIONS](state, data) {
    state.notifications = data.response;
  },
  [NotificationsMutations.SET_NOTIFICATION_READ](state, data) {
    const index = state.notifications.findIndex((message) => message.id === data);
    const updatedNotification = state.notifications[index];
    updatedNotification.isRead = true;
    state.notifications.splice(index, 1, updatedNotification);
  },
};

export const NotificationsModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
