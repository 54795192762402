import { securedHttpClient } from "@/api/httpClient";

export const getNotifications = (customerId: any) => {
  securedHttpClient.get("/notifications", {
    params: {
      customerId: customerId,
    },
  });
  return {
    status: 200,
    data: [
      {
        title: "Application approved",
        subtTitle: "Application for course Maskinfører - Bardufoss 2.1 is approved.",
        route: "courseList",
        isRead: false,
        id: "0",
      },
      {
        title: "Documents require your sign off",
        subtTitle: "New loan agreement is ready for sign off",
        route: "documents",
        isRead: false,
        id: "1",
      },
      {
        title: "New contract for you",
        subtTitle: "Contract for fall semester is available",
        route: "documents",
        isRead: false,
        id: "2",
      },
    ],
  };
};
export const readNotification = (id: any) => {
  securedHttpClient.post("/notifications/read", {
    params: {
      id: id,
    },
  });
};
