var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-skeleton-loader', {
    attrs: {
      "type": _vm.type
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }