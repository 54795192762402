
import { api } from "@/api/api";
import { authService } from "@/authService";
import { CourseInviteStatus } from "@/shared/enums/courseInviteStatus.enum";
import { RolesType } from "@/shared/enums/roleTypesEnum.enums";
import { getFullName, getShortName } from "@/shared/helpers/customerHelpers";
import { isMobile } from "@/shared/helpers/displayHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { useRouteComputed, useRouter, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, Ref, ref, watch } from "@vue/composition-api";
import TheNotifications from "./TheNotifications.vue";
import TheHelpButton from "./TheHelpButton.vue";

enum BadgeEnum {
  UnreadMessageCount = "unreadMessageCount",
  AmountOfCourses = "amountOfCourses",
  AmountOfAssignments = "amountOfAssignments",
  CourseInvitations = "courseInvitations",
  UnsignedDocuments = "unsignedDocuments",
}

interface DrawerBadge {
  [BadgeEnum.UnreadMessageCount]: Ref<number>;
  [BadgeEnum.AmountOfCourses]: Ref<number>;
  [BadgeEnum.AmountOfAssignments]: Ref<number>;
  [BadgeEnum.CourseInvitations]: Ref<number>;
  [BadgeEnum.UnsignedDocuments]: Ref<number>;
}
interface MenuItem {
  title: string;
  icon: string;
  link: string;
  role: string;
  badgeKey?: BadgeEnum;
  badgeColor?: string;
}

export default defineComponent({
  components: { TheNotifications, TheHelpButton },
  name: "TheDrawer",
  setup() {
    const store = useStore<StoreState>();
    const route = useRouteComputed();
    const drawer = ref(false);
    const mini = ref(false);
    const menuItemsDisplayed = ref<MenuItem[]>([]);
    const profileLink = ref("/profile");
    const router = useRouter();
    const badges = ref<DrawerBadge>({
      unreadMessageCount: ref(0),
      amountOfCourses: ref(0),
      amountOfAssignments: ref(0),
      courseInvitations: ref(0),
      unsignedDocuments: ref(0),
    });
    const isResource = computed(() => store.state.hoc.resource);
    const customer = computed(() => store.state.customer.customer);

    const setMenu = (route: string) => {
      if (!route.includes("/")) {
        return;
      }
      const resourceRole = "resource";

      menuItemsDisplayed.value = menuItems.filter(
        (current) => current.role === "all" || (isResource.value && current.role === resourceRole)
      );
      if (isMobile) {
        menuItemsDisplayed.value = [
          ...menuItemsDisplayed.value,
          {
            title: "Profil",
            icon: "mdi-account",
            link: "/profile",
            role: "all",
          },
        ];
      }
    };

    function navigateToProfile() {
      const path = "/profile";
      if (router.currentRoute.fullPath !== path) {
        router.push("/profile");
      }
    }

    function signOut() {
      authService.logout();
    }

    const getUnreadMessageCount = async () => {
      badges.value.unreadMessageCount = (await api.messaging.getNumberOfUnreadMessagesMinSide()).data;
    };

    const getCourseInvitationCount = async () => {
      badges.value.courseInvitations = (await api.course.getCourseInvitationsForUser()).data.filter(
        (invitation) => invitation.status === CourseInviteStatus.new
      ).length;
    };

    const getUnsignedDocumentsCount = async () => {
      // TODO change endpoint or check on status of document
      badges.value.unsignedDocuments = (await api.file.getParticipantFilesAsync()).data.filter(
        (document) => document.category === "Kontrakt"
      ).length;
    };

    const getCoursesAndAssignments = async () => {
      const response = (await api.course.getCourseParticipantAndCoursesByCurrentUserAsync()).data;
      badges.value.amountOfCourses = response.filter((item) => item.roleName === RolesType.Student).length;
      badges.value.amountOfAssignments = response.filter((item) => item.roleName !== RolesType.Student).length;
    };

    onMounted(() => {
      globalLoadingWrapper({}, async () => {
        await Promise.all([
          getUnreadMessageCount(),
          getCoursesAndAssignments(),
          getCourseInvitationCount(),
          getUnsignedDocumentsCount(),
        ]);
        setMenu(route.value.fullPath);
      });
    });

    watch(isResource, () => {
      setMenu(route.value.fullPath);
    });

    watch(route, () => {
      setMenu(route.value.fullPath);
    });

    const activeTitle = computed(() => {
      if (route.value.name === "dashboard") {
        return "Kalender";
      }
      if (route.value.path.includes("/courseInvites")) {
        return "Kursinvitasjoner";
      }
      if (route.value.path.includes("/courseAssignment")) {
        return "Oppdrag";
      }
      if (route.value.path.includes("/course")) {
        return "Kurs og studier";
      }
      if (route.value.path.includes("/documents")) {
        return "Dokumenter";
      }
      if (route.value.path.includes("/message")) {
        return "Meldinger";
      }
      if (route.value.path.includes("/apply")) {
        return "Påmeldingsskjema";
      }
      if (route.value.path.includes("/profile")) {
        return "Profil";
      }
    });

    return {
      customerShortName: computed(() => getShortName(customer.value)),
      customerFullName: computed(() => getFullName(customer.value)),
      badges,
      drawer,
      mini,
      menuItemsDisplayed,
      profileLink,
      setMenu,
      navigateToProfile,
      signOut,
      isMobile,
      activeTitle,
      onSignup: computed(() => route.value.path.includes("/completeSignup")),
    };
  },
});

const menuItems: MenuItem[] = [
  {
    title: "Kalender",
    icon: "mdi-home-city",
    link: "/",
    role: "all",
  },
  {
    title: "Kurs og studier",
    icon: "mdi-book-multiple",
    link: "/course",
    role: "all",
    badgeKey: BadgeEnum.AmountOfCourses,
    badgeColor: "secondary",
  },
  {
    title: "Oppdrag",
    icon: "mdi-book-education",
    link: "/courseAssignment",
    role: "resource",
    badgeKey: BadgeEnum.AmountOfAssignments,
    badgeColor: "secondary",
  },
  {
    title: "Dokumenter",
    icon: "mdi-folder-account",
    link: "/documents",
    role: "all",
    badgeKey: BadgeEnum.UnsignedDocuments,
    badgeColor: "error",
  },
  {
    title: "Kursinvitasjoner",
    icon: "mdi-clipboard-text",
    link: "/courseInvites",
    role: "all",
    badgeKey: BadgeEnum.CourseInvitations,
    badgeColor: "error",
  },
  {
    title: "Meldinger",
    icon: "mdi-email",
    link: "/messages",
    role: "all",
    badgeKey: BadgeEnum.UnreadMessageCount,
    badgeColor: "error",
  },
];
