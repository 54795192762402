import { AttendanceActions } from "@/store/modules/attendance/attendance.actions.enum";
import { CustomerActions } from "@/store/modules/customer/customer.actions.enum";
import { ExpensesActions } from "@/store/modules/expenses/expenses.actions.enum";
import { FileActions } from "@/store/modules/file/file.actions.enum";
import { GradesActions } from "@/store/modules/grades/grades.actions.enum";
import { HocActions } from "@/store/modules/hoc/hoc.actions.enum";
import { HoursActions } from "@/store/modules/hours/hours.actions.enum";
import { MessagesActions } from "@/store/modules/messages/messages.actions.enum";
import { NotificationsActions } from "@/store/modules/notifications/notifications.actions.enum";
import { RegisterActions } from "@/store/modules/register/register.actions.enum";
import { SigninActions } from "@/store/modules/signin/signin.actions.enum";
import { TestActions } from "@/store/modules/test/test.actions.enum";
import { TodosActions } from "@/store/modules/todos/todos.actions.enum";
import { CourseActions } from "@/store/modules/course/course.actions.enum";
import { GlobalActions } from "@/store/modules/global/global.actions.enum";

export const StoreActions = Object.freeze({
  GlobalActions,
  AttendanceActions,
  CustomerActions,
  ExpensesActions,
  FileActions,
  GradesActions,
  HocActions,
  HoursActions,
  MessagesActions,
  NotificationsActions,
  RegisterActions,
  SigninActions, // Refactor name
  TestActions,
  TodosActions,
  CourseActions,
});

// Can this be removed ?
export type StoreActionsType =
  | GlobalActions
  | AttendanceActions
  | CustomerActions
  | ExpensesActions
  | FileActions
  | GradesActions
  | HocActions
  | HoursActions
  | MessagesActions
  | NotificationsActions
  | RegisterActions
  | SigninActions
  | TestActions
  | TodosActions
  | CourseActions;
