import { Ref } from "@vue/composition-api";
import * as mimeTypes from "mime-types";

export function randomNumber(a: number, b: number) {
  return Math.floor((b - a + 1) * Math.random()) + a;
}

export function countHours(attendance: any) {
  if (!attendance) {
    return null;
  }
  const attendanceLength = attendance.length;
  let hoursSummarized = 0;
  for (let i = 0; i < attendanceLength; i++) {
    if (attendance[i].noOfHours > 0) {
      hoursSummarized = hoursSummarized + parseInt(attendance[i].noOfHours);
    }
  }
  return hoursSummarized;
}

export const checkMimeType = (filename: string) => {
  return mimeTypes.extension(filename);
};

export function beginDownloading(content: any, filename: any) {
  const fileURL = window.URL.createObjectURL(new Blob([content]));
  const fileLink = document.createElement("a");
  fileLink.href = fileURL;
  fileLink.setAttribute("download", filename);
  document.body.appendChild(fileLink);
  fileLink.click();
}

const setLoadingRef = (loadingRef: Ref<boolean> | Ref<{ loading: boolean }>, value: boolean) => {
  if (typeof loadingRef.value === "boolean") {
    loadingRef.value = value;
    return;
  }
  loadingRef.value.loading = value;
};

export const withLoadingRef =
  (loadingRef: Ref<boolean> | Ref<{ loading: boolean }>, wrappedFunction: () => Promise<void>) => async () => {
    setLoadingRef(loadingRef, true);

    try {
      await wrappedFunction();
    } finally {
      // Make sure we unblock the UI if any exceptions are thrown
      setLoadingRef(loadingRef, false);
    }
  };
